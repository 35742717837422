import { usePartner } from "./globalContext";

type AnalyticMetadata = {
  userFriendlyLabel: string;
  relatedColor: string;
  relatedIcon: string;
};

export type MetadataMapper = (
  analyticType: string,
  fallback?: Partial<AnalyticMetadata>
) => AnalyticMetadata;

export const useBusinessMappings = () => {
  const { partner } = usePartner();

  const mapMetadata: MetadataMapper = (
    analyticType: string,
    { userFriendlyLabel, relatedColor = "#071C54", relatedIcon = "file" } = {}
  ) => {
    let mapping = partner?.businessDomainMappings?.[analyticType];
    return {
      userFriendlyLabel: userFriendlyLabel ?? camelCaseToLabel(analyticType),
      relatedColor,
      relatedIcon,
      ...mapping,
    };
  };

  const mapLabel = (analyticType: string) =>
    partner?.businessDomainMappings?.[analyticType]?.userFriendlyLabel ||
    camelCaseToLabel(analyticType);

  const mapIcon = (analyticType: string, fallbackIcon = "file") =>
    partner?.businessDomainMappings?.[analyticType]?.relatedIcon ||
    fallbackIcon;

  const mapColor = (analyticType: string, fallbackColor = "#071C54") =>
    partner?.businessDomainMappings?.[analyticType]?.relatedColor ||
    fallbackColor;

  return {
    mapMetadata,
    mapLabel,
    mapIcon,
    mapColor,
  };
};

const camelCaseToLabel = (str: string) => {
  let spaced = str.replace(/[A-Z]/g, (letter) => ` ${letter}`);
  return spaced[0].toUpperCase() + spaced.substring(1);
};
