import { usePartner } from "./globalContext";

export type FormatType = "currency" | "smallCurrency" | "percentage" | "addition";
export type CustomFormatOptions = {
  formatType?: FormatType;
  decimalPlaces?: number;
  showEmptyData?: boolean;
};

const formatCurrency = (value: number) => {
  return `$${defaultFormatNumber(value, 2)}`;
};

const formatSmallCurrency = (value: number) => {
  return `$${defaultFormatNumber(value, 3)}`;
};

const formatPercentage = (value: number) => {
  let formatted = defaultFormatNumber(value, 2);
  let split = formatted.split(".");
  if (split[1] === "00") {
    formatted = split[0];
  }
  return `${formatted}%`;
};

const formatAddition = (value: number) => {
  let formatted = defaultFormatNumber(value);
  if (value > 0) return `+${formatted}`;
  return formatted;
};

const defaultFormatNumber = (value: number, digits: number = 0) => {
  return value.toFixed(digits).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

const defaultCustomFormatOptions: CustomFormatOptions = {
  showEmptyData: false,
  decimalPlaces: 0,
};

export type AnalyticsFormatFunction = (
  valueToFormat: string | number,
  analyticsType?: string,
  formatOptions?: CustomFormatOptions
) => string;

export const useAnalyticsFormatting = (): AnalyticsFormatFunction => {
  const { partner } = usePartner();

  return (
    valueToFormat: string | number,
    analyticsType?: string,
    formatOptions: CustomFormatOptions = defaultCustomFormatOptions
  ): string => {
    if (typeof valueToFormat === "string") {
      valueToFormat = Number(valueToFormat);
    }

    if (!valueToFormat || isNaN(valueToFormat)) valueToFormat = 0;

    if (valueToFormat === 0 && !formatOptions.showEmptyData) return "---";

    let formatTypeFromConfig = analyticsType
      ? partner?.typeFormatMappings[analyticsType]
      : null;
    let formatType = formatTypeFromConfig ?? formatOptions.formatType;

    switch (formatType) {
      case "currency":
        return formatCurrency(valueToFormat);
      case "smallCurrency":
        return formatSmallCurrency(valueToFormat);
      case "percentage":
        return formatPercentage(valueToFormat);
      case "addition":
        return formatAddition(valueToFormat);
      default:
        return defaultFormatNumber(valueToFormat, formatOptions.decimalPlaces);
    }
  };
};
