import { FixedColumnCell, SecondaryValue, SubRowIcon } from "./styles";
import Tooltip from "../tooltip";
import { faLevelUpAlt } from "@fortawesome/free-solid-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
interface DataTableFixedCellProps {
  value: string;
  secondaryValue?: string;
  tooltipText?: string;
  showTooltip?: boolean;
  rowHasSubData: boolean;
  rowType?: "main" | "sub";
}

const DataTableFixedCell = ({
  value,
  secondaryValue,
  tooltipText,
  showTooltip,
  rowHasSubData,
  rowType,
}: DataTableFixedCellProps) => (
  <FixedColumnCell
    className={`${rowHasSubData && "sub-data"} ${rowType ?? ""}`}
  >
    <div className="value-row">
      {showTooltip && rowType === "sub" && (
        <SubRowIcon icon={faLevelUpAlt as IconProp} />
      )}
      {value} {showTooltip && tooltipText && <Tooltip text={tooltipText} />}
    </div>

    {secondaryValue && <SecondaryValue>{secondaryValue}</SecondaryValue>}
  </FixedColumnCell>
);

export default DataTableFixedCell;
