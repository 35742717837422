import React, { Fragment, useEffect, useState } from "react";
import { TotalCard } from "../../components/totalCard";
import { TotalCardProps } from "../../components/totalCard/totalCard";
import {
  Analytics,
  Dependency,
  Filters,
  serializeDateRange,
} from "../../hooks/globalContext";
import { useGlobalAnalytics } from "../../hooks/useGlobalAnalytics";
import { DateRange } from "../../utils/dateRange";
import { MetadataMapper, useBusinessMappings } from "hooks/useBusinessMappings";
import {
  AnalyticsFormatFunction,
  useAnalyticsFormatting,
} from "hooks/useAnalyticsFormatting";
import { IconPrefix } from "@fortawesome/fontawesome";
import TotalCardPlaceholder from "components/totalCard/totalCardPlaceholder";
import getIcon from "utils/getIcon";

export type MetricConfig = {
  primaryAnalytic: string;
  secondaryAnalytic?: string;
  icon: string;
  secondaryAnalyticLabel?: string;
  iconPrefix?: IconPrefix;
  color?: string;
};

export type TotalCardConfig = {
  dateRange: DateRange;
  title: string;
  metrics: MetricConfig[];
  filters?: Filters;
  shouldInheritPageFilters?: boolean;
};

export type ConfigDrivenTotalCardProps = {
  config: TotalCardConfig;
  pageFilters: Filters;
  loading: boolean;
};

export const ConfigDrivenTotalCard: React.FC<ConfigDrivenTotalCardProps> = (
  props
) => {
  const { mapMetadata, mapLabel } = useBusinessMappings();
  const format = useAnalyticsFormatting();
  /** if should inherit from page, default to that */

  const [filters, setFilters] = useState(
    props.config.shouldInheritPageFilters
      ? props.pageFilters ?? props.config.filters
      : props.config.filters ?? {}
  );
  useEffect(() => {
    if (props.config.shouldInheritPageFilters) {
      setFilters(props.pageFilters);
    }
  }, [props.pageFilters, props.config.shouldInheritPageFilters]);

  const dependencies = getAllDependencies(props.config, filters);
  const { loading, dependencyData, setDependencies, setCanFetch } =
    useGlobalAnalytics({
      dependencies,
      dateRange: props.config.dateRange,
      filters,
    });
  useEffect(() => {
    if (!props.loading) {
      setCanFetch(true);
    } else {
      setCanFetch(false);
    }
  }, [props.loading, setCanFetch]);
  const dateRangeKey = serializeDateRange(props.config.dateRange);
  const [rows, setRows] = useState<TotalCardProps["rows"]>([]);
  useEffect(() => {
    if (!loading) {
      setRows(
        dependencyDataToRows(
          props.config.metrics,
          dependencyData[dateRangeKey],
          mapMetadata,
          format
        ).map((row) => ({
          ...row,
          title: mapLabel(row.title),
        })) || []
      );
    }
    //eslint-disable-next-line
  }, [dependencyData]);
  useEffect(() => {
    setDependencies(
      dependencies.map((dependency) => {
        return { ...dependency, filters };
      })
    );
    //eslint-disable-next-line
  }, [filters]);
  if (loading) {
    return (
      <TotalCardPlaceholder
        heading={props.config.title}
        metrics={props.config.metrics}
      />
    );
  }

  return (
    <Fragment>
      {rows.length > 0 && (
        <TotalCard heading={props.config.title} rows={rows} />
      )}
    </Fragment>
  );
};

const dependencyDataToRows: (
  metrics: MetricConfig[],
  data: Analytics,
  mapMetadata: MetadataMapper,
  format: AnalyticsFormatFunction
) => TotalCardProps["rows"] = (metrics, data, mapMetadata, format) => {
  return metrics.map((metric) => {
    const metadata = mapMetadata(metric.primaryAnalytic);
    return {
      title: metric.primaryAnalytic,
      secondaryTitle: metric.secondaryAnalytic,
      color: metric.color ?? metadata.relatedColor,
      icon: getIcon(metric.icon ?? metadata.relatedIcon),
      value: format(
        Object.values(data?.[metric.primaryAnalytic] || {})[0],
        metric.primaryAnalytic,
        { showEmptyData: true }
      ),
      ...buildSecondaryAnalyticWithLabel(data, metric, format),
    };
  });
};
const buildSecondaryAnalyticWithLabel = (
  data: Analytics,
  metric: MetricConfig,
  format: AnalyticsFormatFunction
) => {
  if (metric.secondaryAnalytic) {
    let value = Object.values(data?.[metric.secondaryAnalytic] || { k: 0 })[0];
    let label = metric.secondaryAnalyticLabel
      ? ` of ${metric.secondaryAnalyticLabel}`
      : "";
    return {
      secondaryValue: `${format(value, metric.secondaryAnalytic, {
        showEmptyData: true,
        formatType: "percentage",
      })}${label}`,
    };
  }
  return {};
};

export const getAllDependencies: (
  config: TotalCardConfig,
  filters: Filters
) => Dependency[] = (config, filters) => {
  const dateRange = config.dateRange;

  return config.metrics.flatMap((metricConfig) => {
    //** if theres a secondary, include it. otherwise do not */
    return metricConfig.secondaryAnalytic
      ? [
          {
            analyticType: metricConfig.secondaryAnalytic,
            dateRange,
            filters,
          },
          { analyticType: metricConfig.primaryAnalytic, dateRange, filters },
        ]
      : [{ analyticType: metricConfig.primaryAnalytic, dateRange, filters }];
  });
};
