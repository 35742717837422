import { BrowserRouter as Router } from "react-router-dom";
import AuthContext from "./context/authContext";
import "./styles/global.scss";
import "react-datepicker/dist/react-datepicker.css";
import NotificationContext from "./context/notificationContext";
import mapboxgl from "mapbox-gl";
// eslint-disable-next-line import/no-webpack-loader-syntax
import workerClass from "worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker";
import { GlobalContext } from "hooks/globalContext";
import Main from "./main";
import { ThemeProvider } from "styled-components";
import { themes, GlobalStyles } from "./theme";

import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { faFacebookF } from "@fortawesome/free-brands-svg-icons";
import StagingIndicator from "./utils/stagingIndicator";

library.add(fas, faFacebookF);

//This is needed to make mapbox work in production, see https://github.com/mapbox/mapbox-gl-js/issues/10173
mapboxgl.workerClass = workerClass;
// eslint-disable-next-line import/no-webpack-loader-syntax
// require("worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker").default;

const App = () => {
  return (
    <ThemeProvider theme={themes.light}>
      <GlobalStyles />
      <Router>
        <AuthContext>
          <NotificationContext>
            <GlobalContext>
              <Main></Main>
            </GlobalContext>
          </NotificationContext>
        </AuthContext>
      </Router>
      <StagingIndicator />
    </ThemeProvider>
  );
};

export default App;
