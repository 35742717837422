import countryGeoJSON from "../utils/countryGeoJSON.json";
import countryPositionData from "../utils/lat_long_codes.json";
import bbox from "@turf/bbox";
import pointIsInPolygon from "@turf/boolean-point-in-polygon";
import { randomPoint } from "@turf/random";
const mapGeoJSON = (geoJSON) => {
  let countries = {};
  geoJSON.features.forEach((feature) => {
    countries[feature.properties.ADMIN.toUpperCase()] = feature;
  });
  return countries;
};

const mapPositions = (data) => {
  let countries = {};
  data.forEach(({ country, alpha2, alpha3, latitude, longitude }) => {
    const pos = [longitude, latitude];
    countries[country.toUpperCase()] = pos;
    countries[alpha2] = pos;
    countries[alpha3] = pos;
  });
  return countries;
};

const mapNames = (data) => {
  let countries = {};
  data.forEach(({ country, alpha2, alpha3 }) => {
    countries[country.toUpperCase()] = country;
    countries[alpha2] = country;
    countries[alpha3] = country;
  });
  return countries;
};

class CountryService {
  static geoJSON = countryGeoJSON;
  static countryBoundsMap = mapGeoJSON(this.geoJSON);
  static countryLatLongs = countryPositionData.ref_country_codes;
  static countryPositionsMap = mapPositions(this.countryLatLongs);
  static countryNamesMap = mapNames(this.countryLatLongs);

  /**
   * Gets all lat/long bounds for a country
   * @param {String} name The country name
   * @returns {any[]}
   */
  static getCountryBounds(name) {
    return this.countryBoundsMap[this.getCountryName(name)?.toUpperCase()];
  }

  /**
   * Gets the coordinates of a country, helpful if an object is missing location data
   * @param {String} name The country name, ISO2 code, or ISO3 code
   * @returns {Number[]}
   */
  static getCountryCoords(name) {
    return name ? this.countryPositionsMap[name.toUpperCase()] : null;
  }

  //Including depth for debugging
  static getRandomCoordsInCountry(name, depth = 0) {
    if (!name) return null;

    const countriesToRandomize = ["cn"];
    if (!countriesToRandomize.includes(name)) {
      return null;
    }

    const bounds = this.getCountryBounds(name)?.geometry;
    if (bounds) {
      // get boundedbox
      const countryBox = bbox(bounds);
      //get random coords inside boundedBox
      const randomCoords = randomPoint(1, { bbox: countryBox });
      //check if inside bounds for country
      const coordsInCountry = pointIsInPolygon(
        randomCoords.features[0].geometry,
        bounds
      );
      // recursively call until we find one inside the country
      return coordsInCountry
        ? randomCoords.features[0].geometry.coordinates
        : this.getRandomCoordsInCountry(name, depth + 1);
    } else {
      return [0, 0];
    }
  }

  /**
   * Gets a country name from a code (or confirms a name exists)
   * @param {String} code A country name/code, either ISO2 or ISO3
   * @returns {String}
   */
  static getCountryName(code) {
    return code ? this.countryNamesMap[code.toUpperCase()] : null;
  }

  /**
   * Transforms map data by:
   * - Trying to locate missing data by country
   * - Removing points with missing data
   * - Scattering points with the same location
   * @param {Object[]} data Unparsed map data
   * @param {boolean} skipLocationScattering
   * @returns {Object[]}
   */
  static parseLocationData(data, skipLocationScattering = false) {
    let parsed = [];
    for (let row of data) {
      let coords =
        row.coords?.[0] === 0 && row.coords?.[1] === 0 && row.country !== "?"
          ? this.getRandomCoordsInCountry(row.country) ?? row.coords
          : row.coords;
      let country = this.getCountryName(row.country);
      if (coords?.[0] !== 0 || coords?.[1] !== 0) {
        parsed.push({
          ...row,
          coords,
          country,
        });
      }
    }
    return !skipLocationScattering ? this.scatterLocations(parsed) : parsed;
  }

  /**
   * Scatters repeated locations within a data set
   * @param {Object[]} data Map data
   * @returns {Object[]}
   */
  static scatterLocations(data) {
    let locationMap = {};
    for (let [i, { coords }] of data.entries()) {
      if (!coords) continue;

      let key = coords.toString();
      if (!locationMap[key]) {
        locationMap[key] = [];
      }
      locationMap[key].push(i);
    }

    for (let indeces of Object.values(locationMap)) {
      if (indeces.length > 1) {
        for (let i = 1; i < indeces.length; i++) {
          data[indeces[i]].coords = this.scatterLocation(
            data[indeces[i]].coords
          );
        }
      }
    }
    return data;
  }

  /**
   * Moves a coordinate set randomly within a given interval
   * @param {Number[]} coords Coordinate set to move
   * @returns {Number[]}
   */
  static scatterLocation(coords) {
    //Random radius to scatter points within
    let radius = 0.25 * Math.random() + 0.1;

    //Math
    let angle = Math.random() * 2 * Math.PI,
      hyp = Math.sqrt(Math.random()) * radius,
      adj = Math.cos(angle) * hyp,
      opp = Math.sin(angle) * hyp;
    return [coords[0] + adj, coords[1] + opp];
  }

  /**
   * Gets a random country from data, or simply selects a random country from those available
   * @param {Object[]} data Array of data to select a country from
   * @param {String} previous The previously selected country
   * @returns {String}
   */
  static getRandomCountry(data, previous) {
    let countries = [];
    if (data.length > 0) {
      let existingCountries = [];
      data.forEach((point) => {
        existingCountries[point.country] = true;
      });
      countries = Object.keys(existingCountries).filter(
        (name) => !!this.getCountryCoords(name)
      );
    } else {
      countries = this.countryLatLongs.map(({ country }) => country);
    }

    let index = countries.indexOf(previous);
    let nextIndex = index;
    while (nextIndex === index) {
      nextIndex = Math.floor(Math.random() * countries.length);
    }
    return countries[nextIndex];
  }
}

export default CountryService;
