import { useEffect, useState } from "react";
import { Filter } from "./styles";
import PageFilterToggle from "./pageFilterToggle";
import PageFilterMenu from "./pageFilterMenu";
import { CategoryUpdateData } from "hooks/usePageFilters";
import { FilterOptionCategory, Filters } from "hooks/globalContext";

type UpdateCategoriesFunction = (updates: CategoryUpdateData[]) => void;
type PageFilterProps = {
  /**the options that will be available to the user to select graphically */
  categories: FilterOptionCategory[];
  /** the update function that this component will use to call out to it's parent */
  onUpdate: UpdateCategoriesFunction;
  /**
   * the filters that will be submitted to the Analytics engine.
   * this may include filters outside the scope of this.categories
   * which the user should not need to be concerned about
   */
  currentFilters: Filters;
};
export type FilterCategoryValue = { [key: string]: boolean };
export type FilterCategoriesObject = {
  [key: FilterOptionCategory["category"]]: FilterCategoryValue;
};
const createValuesObj = (
  category: FilterOptionCategory,
  value: boolean,
  currentValues?: string[]
): FilterCategoryValue => {
  const values = category.values.reduce(
    (res, currentValue: string | boolean) => {
      return {
        ...res,
        [currentValue.toString()]:
          currentValues /** if no current values, return the value prop given */
            ? currentValues.includes(
                currentValue.toString()
              ) /** if current values, see if it includes the current value we're checking for */
              ? value /** if it does include it, return value */
              : !value /** else return inverse of value */
            : value,
      };
    },
    {}
  );
  return values;
};

const PageFilter = ({
  categories,
  onUpdate,
  currentFilters,
}: PageFilterProps) => {
  const [showMenu, setShowMenu] = useState(false);
  const [filters, setFilters] = useState<FilterCategoriesObject>({});
  useEffect(() => {
    let baseFilters: FilterCategoriesObject = {};
    for (let { category } of categories || []) {
      baseFilters[category] = createValuesObj(
        categories.find((cat) => cat.category === category),
        true,
        currentFilters[category]
      );
    }
    setFilters(baseFilters);
  }, [categories, currentFilters]);

  const onChange = (category: string, key: string, value: boolean) => {
    setFilters({
      ...filters,
      [category]: {
        ...filters[category],
        [key]: value,
      },
    });
  };

  const onAllChange = (category: string, value: boolean) => {
    setFilters({
      ...filters,
      [category]: Object.entries(filters[category]).reduce(
        (acc, [k, v]) => ({ ...acc, [k]: value }),
        {}
      ),
    });
  };

  const onUpdateClick = () => {
    let updateData = [];
    for (let [name, values] of Object.entries(filters)) {
      updateData.push({ category: name, values });
    }
    onUpdate(updateData);
  };

  return (
    <Filter>
      <PageFilterToggle onClick={() => setShowMenu(!showMenu)} />
      <PageFilterMenu
        active={showMenu}
        onCollapse={() => setShowMenu(false)}
        filters={filters}
        onChange={onChange}
        onAllChange={onAllChange}
        onUpdateClick={onUpdateClick}
      />
    </Filter>
  );
};

export default PageFilter;
