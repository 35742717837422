import React from "react";
import { TableRow } from "./styles";
import DataTableDataCell from "./dataTableDataCell";
import { DataTableRow } from "../types/dataTableRow";

interface DataTableDataRowProps {
  row: DataTableRow;
}

const DataTableDataRow = ({ row }: DataTableDataRowProps) => (
  <TableRow
    className={`${!!row?.total?.secondaryValue && "sub-data"} ${
      row.rowType ?? ""
    }`}
  >
    {row?.data.map((data, i) => (
      <DataTableDataCell key={i} {...data} />
    ))}
  </TableRow>
);

export default DataTableDataRow;
