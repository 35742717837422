import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faImage } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  ProfileClose,
  ProfileHeader,
  ProfileHeaderDivider,
  ProfileHeaderInfo,
  ProfilePicture,
  ProfileTitle,
  ProfileType,
  ViewButton,
} from "./seekerProfile.styles";
import { SeekerProfile, SeekerProfileType } from "./types";

type SeekerProfileHeaderProps = {
  profile: SeekerProfile;
  onClose: () => void;
};

const SeekerProfileHeader: React.FC<SeekerProfileHeaderProps> = ({
  profile,
  onClose,
}) => {
  const name = profile.nickname ?? profile.firstName;
  const type: SeekerProfileType = "attending";

  const viewSeeker = () => {
    window.open(
      `${process.env.REACT_APP_TRINITY_ROCK_URL}/Person/${profile.rockID}`,
      "_blank"
    );
  };

  return (
    <ProfileHeader>
      <ProfileTitle>Seeker Impact Profile</ProfileTitle>
      <ProfileClose onClick={onClose}>Close</ProfileClose>
      <ProfileHeaderDivider />
      <ProfileHeaderInfo>
        <ProfilePicture>
          {!!profile.profilePhotoUrl ? (
            <img src={profile.profilePhotoUrl} alt="Profile" />
          ) : (
            <FontAwesomeIcon icon={faImage as IconProp} size="lg" />
          )}
        </ProfilePicture>
        <div>
          <h3>{name}</h3>
          <ProfileType type={type}>{type}</ProfileType>
        </div>
      </ProfileHeaderInfo>
      <ViewButton type="gray" onClick={viewSeeker}>
        View
      </ViewButton>
    </ProfileHeader>
  );
};

export default SeekerProfileHeader;
