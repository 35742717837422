import styled from "styled-components";
import Card from "components/card";

export const DataCardWrapper = styled(Card.Body)`
  min-width: 375px;
  height: 225px;
  display: grid;
  grid-template-columns: 100px 1fr;
  gap: ${({ theme }) => theme?.utilities.spacing["space-md"]};
  align-items: center;
  position: relative;
`;

export const DataCardIcon = styled.div<{ color: string }>`
  width: 100px;
  height: 100px;
  border-radius: 50%;
  border: 5px solid ${({ color }) => color};
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const DataCardData = styled.div`
  justify-self: end;
  text-align: right;
  h1 {
    font-size: 3rem;
    line-height: 3.5rem;
  }
  p {
    line-height: 1rem;
    font-size: 1rem;
    opacity: 0.5;
    font-weight: bold;
  }
`;

const getPercentageColor = (value: number) => {
  if (value === 0) return "#00000080";
  else return value > 0 ? "#00C45A" : "#FF3743";
};

export const DataCardPercentage = styled.p<{ value: number }>`
  position: absolute;
  bottom: ${({ theme }) => theme?.utilities.spacing["space-sm"]};
  right: ${({ theme }) => theme?.utilities.spacing["space-md"]};
  font-size: 0.85rem;
  color: ${({ value }) => getPercentageColor(value)};
`;
