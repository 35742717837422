import styled from "styled-components";


export const ToggleSwitcherWrapper = styled.div`
  width: 100%;
  display: flex;
  z-index: 5;
  position: absolute;
  top: 30px;
  right: 8rem;
  justify-content: flex-end;
`;

export const Toggle = styled.div`
  padding: ${({ theme }) => theme.utilities?.spacing?.["space-sm"]};
  display: flex;
  align-items: center;
  font-size: 1rem;
  font-weight: bold;
  flex-direction: column;
  p{
    margin-bottom: 5px;
  }
`;
export const ToggleItems = styled.div`
  background-color: ${({ theme }) => theme.colors?.["ui-gray-lighter"]};
  border-radius: ${({ theme }) => theme?.utilities.borders["border-radius-md"]};
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.25);
  display: flex;
  align-items: center;
  overflow: hidden;

  .toggle-switch {
  position: relative;
  width: 50px;
  display: inline-block;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  text-align: left;
  &-checkbox {
    display: none;
  }
  &-label {
    display: block;
    overflow: hidden;
    cursor: pointer;
    border: 0 solid #bbb;
    border-radius: 20px;
    margin: 0;
  }
  &-inner {
    display: block;
    width: 200%;
    margin-left: -100%;
    transition: margin 0.3s ease-in 0s;
    &:before,
    &:after {
      display: block;
      float: left;
      width: 50%;
      height: 20px;
      padding: 0;
      line-height: 34px;
      font-size: 14px;
      color: white;
      font-weight: bold;
      box-sizing: border-box;
    }
    &:before {
      content: "";
      padding-left: 10px;
      background-color: ${({ theme }) => theme.colors?.["primary-light"]};
      color: #fff;
    }
  }
  &-disabled {
    background-color: #ddd;
    cursor: not-allowed;
    &:before {
      background-color: #ddd;
      cursor: not-allowed;
    }
  }
  &-inner:after {
    content: "";
    padding-right: 10px;
    background-color: #bbb;
    color: #fff;
    text-align: right;
  }
  &-switch {
    display: block;
    width: 16px;
    margin: 2px;
    background: #fff;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 30px;
    border: 0 solid #bbb;
    border-radius: 20px;
    transition: all 0.3s ease-in 0s;
  }
  .toggle-switch-checkbox:checked + .toggle-switch-label {
    .toggle-switch-inner {
      margin-left: 0;
    }
    .toggle-switch-switch {
      right: 0px;
    }
  }
  
}

`;
