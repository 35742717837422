import styled from "styled-components";
import { rowHeight } from "../reportTable.styles";

const cellWidth = "100px";

export const ReportTableBodyWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;

  .scroll-container {
    width: 100%;
    &::-webkit-scrollbar {
      height: 4px;
    }

    &::-webkit-scrollbar-track {
      border-radius: ${({ theme }) =>
        theme?.utilities.borders["border-radius-md"]};
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      background: #c4c4c425;
      opacity: 0.4;
      box-shadow: 0 0 2px 4px rgba(0, 0, 0, 0) inset;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: ${({ theme }) =>
        theme?.utilities.borders["border-radius-md"]};
      background: ${({ theme }) => theme?.colors?.["scrollbar"]};

      &:hover {
        background: ${({ theme }) => theme?.colors?.["table-line"]};
      }
    }
  }
`;

export const ReportTableBodyRow = styled.div`
  height: ${rowHeight};
  min-width: 100%;
  display: flex;
  align-items: center;
  width: fit-content;

  &:nth-child(even) {
    background-color: #c4c4c425;
  }
`;

export const ReportTableBodyHeaderRow = styled(ReportTableBodyRow)`
  border-bottom: 2px solid #00000025;
  font-weight: bold;
`;

export const ReportTableBodyCell = styled.div`
  min-width: ${cellWidth};
  flex-grow: 1;
  height: ${rowHeight};
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 ${({ theme }) => theme?.utilities.spacing["space-sm"]};
  font-size: 0.9rem;
`;
