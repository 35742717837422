import React from "react";
import Button from "../../button/button";
import Card from "../../card";
import {
  Menu,
  MenuBody,
  MenuCollapseButton,
  MenuFooter,
  MenuHeader,
} from "./styles";
import PageFilterMenuCategory from "../pageFilterMenuCategory";

type PageFilterMenuProps = {
  filters: {
    [category: string]: {
      [key: string]: boolean;
    };
  };
  onChange: (category: string, key: string, value: boolean) => void;
  onAllChange: (category: string, value: boolean) => void;
  active: boolean;
  onCollapse: () => void;
  onUpdateClick: () => void;
};

const PageFilterMenu = ({
  filters,
  onChange,
  onAllChange,
  active,
  onCollapse,
  onUpdateClick,
}: PageFilterMenuProps) => {
  return (
    <Menu className={active && "active"}>
      <Card.Body>
        <MenuHeader>
          <h3>Filters</h3>
          <MenuCollapseButton onClick={onCollapse}>
            <div className="menu-icon__bar menu-icon__bar--1"></div>
            <div className="menu-icon__bar menu-icon__bar--3"></div>
          </MenuCollapseButton>
        </MenuHeader>

        <MenuBody>
          {Object.entries(filters).map(([name, values]) => (
            <PageFilterMenuCategory
              key={name}
              category={name}
              values={values}
              onChange={(key, value) => onChange(name, key, value)}
              onAllChange={(value) => onAllChange(name, value)}
            />
          ))}
        </MenuBody>

        <MenuFooter>
          <Button type="secondary" onClick={onUpdateClick}>
            Update Data
          </Button>
        </MenuFooter>
      </Card.Body>
    </Menu>
  );
};

export default PageFilterMenu;
