import { useAnalyticsFormatting } from "hooks/useAnalyticsFormatting";
import { useBusinessMappings } from "hooks/useBusinessMappings";
import styled from "styled-components";

const TooltipWrapper = styled.div`
  padding: 4px;
`;

const TooltipBody = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.colors["ui-gray-lighter"]};
  border-radius: ${({ theme }) => theme.utilities.borders["border-radius-sm"]};
  padding: ${({ theme }) => theme.utilities.spacing["space-xs"]}
    ${({ theme }) => theme.utilities.spacing["space-sm"]};
  box-shadow: 0px 2px 4px #00000050;
  display: flex;
  flex-direction: column;
`;

const TooltipLabel = styled.h5``;

const TooltipValue = styled.h3<{ color: string }>`
  color: ${({ color }) => color};

  span {
    font-weight: normal;
  }
`;

type TooltipPayload = {
  active: boolean;
  payload: {
    value: number;
    dataKey: string;
    fill: string;
  }[];
  label: string;
};

const AreaGraphTooltip = ({ active, payload, label }: TooltipPayload) => {
  const format = useAnalyticsFormatting();
  const { mapLabel } = useBusinessMappings();

  if (active && payload?.length) {
    let { dataKey, value, fill } = payload[0];
    return (
      <TooltipWrapper>
        <TooltipBody>
          <TooltipLabel>
            {label} - {mapLabel(dataKey)}
          </TooltipLabel>
          <TooltipValue color={fill}>
            {format(value, dataKey, { showEmptyData: true })}
          </TooltipValue>
        </TooltipBody>
      </TooltipWrapper>
    );
  } else {
    return null;
  }
};

export default AreaGraphTooltip;
