import { IconProp } from "@fortawesome/fontawesome-svg-core";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import MapSidebarFilters, {
  MapSidebarFiltersProps,
} from "./mapSidebarFilters/mapSidebarFilters";
import { Sidebar, SidebarToggle, SidebarWrapper } from "./styles";

type MapSidebarProps = MapSidebarFiltersProps & {
  useCustomFilters?: boolean;
};

const MapSidebar: React.FC<MapSidebarProps> = ({
  children,
  useCustomFilters,
  ...filterProps
}) => {
  const [isOpen, setIsOpen] = useState(false);

  let toggleDashboardMode = () => {
    setIsOpen(false);
    filterProps.toggleDashboardMode();
  };

  return (
    <SidebarWrapper className={isOpen && "open"}>
      <SidebarToggle
        className="btn btn-secondary toggle"
        onClick={() => {
          setIsOpen(!isOpen);
          //dashboardMode && setDashboardMode(false);
        }}
      >
        <FontAwesomeIcon
          icon={(isOpen ? faChevronRight : faChevronLeft) as IconProp}
        />
      </SidebarToggle>
      <Sidebar>
        {!useCustomFilters && (
          <MapSidebarFilters
            {...filterProps}
            toggleDashboardMode={toggleDashboardMode}
          />
        )}
        {children}
      </Sidebar>
    </SidebarWrapper>
  );
};

export default MapSidebar;
