import styled from "styled-components";

export const TooltipWrapper = styled.div`
  margin-left: ${({ theme }) => theme?.utilities.spacing?.["space-xs"]};
  position: relative;
  cursor: help;

  .tooltip-text {
    background-color: ${({ theme }) => theme?.colors?.["ui-gray-lighter"]};
    padding: ${({ theme }) =>
      theme?.utilities.spacing?.["space-xs"] +
      " " +
      theme?.utilities.spacing?.["space-sm"]};
    border-radius: ${({ theme }) =>
      theme?.utilities.borders?.["border-radius-sm"]};
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5);
    color: ${({ theme }) => theme?.colors?.["text-dark"]};
    font-size: 0.9rem;

    position: absolute;
    left: calc(100% + 0.5rem + 5px);
    top: 50%;
    transform: translateY(-50%);
    z-index: 10;

    &::after {
      content: "";
      position: absolute;
      top: 50%;
      right: 100%;
      margin-top: -5px;
      border: 5px solid transparent;
      border-right-color: ${({ theme }) => theme?.colors?.["ui-gray-lighter"]};
    }
  }
`;
