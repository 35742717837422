import styled from "styled-components";
import Card from "components/card";

export const rowHeight = "50px";

export const ReportTableWrapper = styled(Card.Body)`
  width: 100%;
  padding: 0;
  display: grid;
  grid-template-columns: auto 1fr auto;
`;

export const ReportTableTitleColumn = styled.div`
  width: 100%;
  margin-top: calc(${rowHeight} - 2px);
  padding: 0 ${({ theme }) => theme?.utilities.spacing["space-md"]};
  display: flex;
  flex-direction: column;
  border-right: 2px solid #00000025;
`;

export const ReportTableTitleWrapper = styled.div`
  height: ${rowHeight};
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme?.utilities.spacing["space-sm"]};
`;

export const ReportTableIcon = styled.div<{ color: string }>`
  width: 35px;
  height: 35px;
  border-radius: 50%;
  border: 3px solid ${({ color }) => color};
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ReportTableTitle = styled.p`
  font-weight: bold;
`;

export const ReportTableTotalColumn = styled.div`
  text-align: right;
  display: flex;
  flex-direction: column;
`;

export const ReportTableTotalColumnCell = styled.div`
  height: calc(${rowHeight} - 2px);
  display: flex;
  align-items: center;
  padding: 0 ${({ theme }) => theme?.utilities.spacing["space-md"]};
  font-weight: bold;
`;

export const ReportTableTotalColumnData = styled.div`
  border-left: 2px solid #00000025;
`;

export const ReportTableTotal = styled(ReportTableTotalColumnCell)`
  height: ${rowHeight};
  border-bottom: none;
`;

export const ReportTableColumnFiller = styled.div`
  width: 100%;
  flex-grow: 1;
  border-left: 2px solid #00000025;
`;
