import { Dependency, Filters, serializeDateRange } from "hooks/globalContext";
import {
  DateRange,
  DateRangeConfig,
  dateRangeFromConfig,
} from "utils/dateRange";
import DataCard from "components/dataCard";
import { DataCardRowCards, DataCardRowWrapper } from "./dataCardRow.styles";
import ReportHeader from "components/reportHeader";
import { useBusinessMappings } from "hooks/useBusinessMappings";
import { useAnalyticsFormatting } from "hooks/useAnalyticsFormatting";
import { useGlobalAnalytics } from "hooks/useGlobalAnalytics";
import { useEffect, useState } from "react";
import { DataCardProps } from "components/dataCard/dataCard";
import DataCardPlaceholder from "components/dataCard/dataCardPlaceholder";

type DataCardRowProps = {
  config: {
    title: string;
    subtitle: string;
    cards: {
      analyticType: string;
      color?: string;
      icon?: string;
      showPercentage?: boolean;
    }[];
    dateRangeConfig: DateRangeConfig;
    filters: Filters;
    shouldInheritPageFilters?: boolean;
  };
  loading: boolean;
  pageFilters: Filters;
  key: number;
};

const DataCardRow: React.FC<DataCardRowProps> = (props) => {
  const dateRange = dateRangeFromConfig(props.config.dateRangeConfig);
  const previousDateRange = getPreviousDateRange();

  const [filters, setFilters] = useState(
    props.config.shouldInheritPageFilters
      ? props.pageFilters
      : props.config.filters
  );
  useEffect(() => {
    if (props.config.shouldInheritPageFilters) {
      setFilters(props.pageFilters);
    }
  }, [props.pageFilters, props.config.shouldInheritPageFilters]);

  const allDependencies = getAllDependencies(
    props.config.cards,
    dateRange,
    filters
  );
  const {
    loading: loadingDependencies,
    dependencyData: nestedDependencies,
    dependencies,
    setDependencies,
    setCanFetch,
  } = useGlobalAnalytics({
    dependencies: allDependencies,
    dateRange: dateRange,
    filters,
  });
  const [dependencyData, setDependencyData] = useState({
    current: nestedDependencies[serializeDateRange(dateRange)] || {},
    previous: nestedDependencies[serializeDateRange(previousDateRange)] || {},
  });

  useEffect(() => {
    if (!(props.loading || loadingDependencies)) {
      setDependencyData({
        current: nestedDependencies[serializeDateRange(dateRange)] || {},
        previous:
          nestedDependencies[serializeDateRange(previousDateRange)] || {},
      });
    }
    //eslint-disable-next-line
  }, [nestedDependencies, props.loading, loadingDependencies]);

  useEffect(() => {
    if (!props.loading) {
      setCanFetch(true);
    } else {
      setCanFetch(false);
    }
  }, [props.loading, setCanFetch]);

  useEffect(() => {
    setDependencies(
      dependencies.map((dependency) => {
        return { ...dependency, filters };
      })
    );
    //eslint-disable-next-line
  }, [filters]);

  const { mapMetadata } = useBusinessMappings();
  const format = useAnalyticsFormatting();

  const cards: DataCardProps[] = props.config.cards.map((card) => {
    let currentValue = Object.values(
      dependencyData.current[card.analyticType] || {}
    )?.[0] as unknown as number;

    let previousValue = Object.values(
      dependencyData.previous[card.analyticType] || {}
    )?.[0] as unknown as number;

    const metadata = mapMetadata(card.analyticType);
    return {
      title: metadata.userFriendlyLabel,
      value: format(currentValue, card.analyticType, { showEmptyData: true }),
      icon: card.icon ?? metadata.relatedIcon,
      color: card.color ?? metadata.relatedColor,
      percentChange: card?.showPercentage
        ? getPercentageDifference(currentValue, previousValue)
        : null,
    };
  });

  return (
    <DataCardRowWrapper key={props.key}>
      <ReportHeader
        title={props.config.title}
        subtitle={props.config.subtitle}
      />
      <DataCardRowCards>
        {!(props.loading || loadingDependencies)
          ? cards.map((cardProps) => <DataCard {...cardProps} />)
          : props.config.cards.map((_, i) => <DataCardPlaceholder key={i} />)}
      </DataCardRowCards>
    </DataCardRowWrapper>
  );
};

/**wrapper for the below generator to clean up the function signature */
const getAllDependencies: (
  cards: DataCardRowProps["config"]["cards"],
  dateRange: DateRange,
  filters: Filters
) => Dependency[] = (cards, dateRange, filters) => {
  return cards.reduce((res, card) => {
    res.push({
      analyticType: card.analyticType,
      dateRange,
      filters,
    });

    if (card?.showPercentage) {
      res.push({
        analyticType: card.analyticType,
        dateRange: getPreviousDateRange(),
        filters,
      });
    }
    return res;
  }, []);
};

const getPercentageDifference = (current: number, previous: number) => {
  if ((!current && !previous) || current - previous === 0) return 0;
  if (!current) current = 0;
  if (!previous) return 1;
  return (current - previous) / previous;
};

const getPreviousDateRange = () =>
  dateRangeFromConfig({
    startDate: "beginning-of-1-month-ago",
    endDate: "1-month-ago",
    binWidth: "total",
  });

export const Factory = (props: DataCardRowProps) => {
  return {
    Component: ({ key, pageFilters, loading }) =>
      DataCardRow({ ...props, key, pageFilters, loading }),
    getInitialDependencies: () => {
      return getAllDependencies(
        props.config.cards,
        dateRangeFromConfig(props.config.dateRangeConfig),
        props.config.shouldInheritPageFilters
          ? props.pageFilters
          : props.config.filters
      );
    },
  };
};
export default Factory;
