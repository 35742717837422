import Button from "components/button/button";
import styled, { keyframes } from "styled-components";
import { SeekerProfileType } from "./types";

export const ModalEnter = keyframes`
  0% {
    transform: translateY(-100vh);
  }
  100% {
    transform: translateY(0);
  }
`;

export const ModalLeave = keyframes`
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-100vh);
  }
`;

export const ModalBackground = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: #33333340;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99;
`;

export const ProfileWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 100;

  display: flex;
  align-items: center;
  justify-content: center;

  &:not(.leaving) {
    animation: 0.35s cubic-bezier(0.175, 0.885, 0.32, 1.275) 0s 1 ${ModalEnter};
  }

  &.leaving {
    transform: translateY(-100vh);
    animation: 0.35s cubic-bezier(0.68, 0, 0.83, -0.275) 0s 1 ${ModalLeave};
  }
  pointer-events: none;
`;

export const Profile = styled.div`
  pointer-events: auto;

  position: relative;
  width: 800px;
  background-color: ${({ theme }) => theme?.colors["ui-gray-lighter"]};
  border-radius: ${({ theme }) => theme?.utilities.borders["border-radius-md"]};
  box-shadow: 0px 2px 6px #000000;
  overflow: hidden;
`;

export const ProfileHeader = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme?.colors["primary-dark"]};
  padding: ${({ theme }) => theme?.utilities.spacing["space-sm"]}
    ${({ theme }) => theme?.utilities.spacing["space-md"]};
  color: white;
  display: grid;
  row-gap: ${({ theme }) => theme?.utilities.spacing["space-sm"]};
  grid-template-columns: 1fr auto;
  grid-template-areas:
    "title close"
    "divider divider"
    "info view";

  p {
    margin: 0 !important;
  }
`;

export const ProfileTitle = styled.p`
  font-weight: bold;
  grid-area: title;
`;

export const ProfileClose = styled.p`
  font-weight: bold;
  grid-area: close;
  justify-self: end;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`;

export const ProfileHeaderDivider = styled.div`
  grid-area: divider;
  width: 100%;
  height: 1px;
  background-color: #ffffff15;
`;

export const ProfileHeaderInfo = styled.div`
  grid-area: info;
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme?.utilities.spacing["space-sm"]};
`;

export const ProfilePicture = styled.div`
  width: 75px;
  height: 75px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme?.colors["ui-gray-dark"]};
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  border: 2px solid ${({ theme }) => theme?.colors["ui-gray-darker"]};

  svg {
    color: #00000015;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

const getProfileTypeColor = (type: SeekerProfileType, theme: any) => {
  switch (type) {
    case "connected":
      return theme?.colors["primary-light"];
    case "attending":
      return theme?.colors["secondary-default"];
    case "committed":
      return "#22814F";
  }
};

export const ProfileType = styled.p<{ type: SeekerProfileType }>`
  font-style: italic;
  color: ${({ type, theme }) => getProfileTypeColor(type, theme)};
  text-transform: capitalize;
`;

export const ViewButton = styled(Button)`
  grid-area: view;
  height: fit-content;
  align-self: center;
  padding: 0.5rem 1rem;
  font-size: 1rem;
`;
