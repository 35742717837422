import { Link } from "react-router-dom";
import styled from "styled-components";

export const NavStoryWrapper = styled.div`
  height: 100vh;
  width: 100%;
  display: grid;
  grid-template-columns: 100px 1fr;
  grid-template-rows: 1fr;
  grid-template-areas: "sidebar empty";
`;

export const NavWrapper = styled.div`
  grid-area: sidebar;
  width: 100%;
  height: 100%;
`;

export const Nav = styled.div`
  position: sticky;
  top: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin: 0;
  background-color: ${({ theme }) => theme.colors?.["ui-gray-lighter"]};
  width: 100%;
  height: 100%;
  box-shadow: 0px 2px 4px rgba(22, 38, 77, 0.2);
  z-index: 2;
  padding: ${({ theme }) => theme.utilities?.spacing?.["space-lg"]} 0
    ${({ theme }) => theme.utilities?.spacing?.["space-md"]};
  max-height: 100vh;

  .logo {
    width: 60px;
    margin-bottom: ${({ theme }) => theme.utilities?.spacing?.["space-md"]};
  }
`;

export const NavItemTitle = styled.div`
  height: 100%;
  position: absolute;
  left: 100%;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  width: 0;
  padding: 0;

  p {
    background-color: white;
    color: ${({ theme }) => theme.colors?.["primary-dark"]};
    border-radius: ${({ theme }) =>
      theme.utilities.borders["border-radius-sm"]};
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.5);
    overflow: hidden;
    text-align: left;
    white-space: nowrap;

    transform: translateX(-100%);
    padding: ${({ theme }) => theme.utilities?.spacing?.["space-xs"]}
      ${({ theme }) => theme.utilities?.spacing?.["space-sm"]};
    transition: 0.3s all;
  }
`;

export const NavItem = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-top: ${({ theme }) => theme.utilities?.spacing?.["space-sm"]};
  padding-bottom: ${({ theme }) => theme.utilities?.spacing?.["space-sm"]};
  min-height: 80px;
  border-radius: 0;
  position: relative;
  transition: 0.2s;
  cursor: pointer;
  font-weight: bold;

  &.active {
    background-color: ${({ theme }) => theme.colors?.["primary-light"]};
    .icon {
      color: white;
    }
  }

  &.active:hover {
    background-color: ${({ theme }) => theme.colors?.["primary-light"]};
  }

  &:not(.active):hover {
    background-color: ${({ theme }) => theme.colors?.["ui-gray-dark"]};
  }

  .icon {
    width: 1.75rem;
    height: 2rem;
    margin-bottom: 2px;
    color: ${({ theme }) => theme.colors?.["primary-darker"]};
  }

  &:hover ${NavItemTitle} {
    width: unset;
    padding: ${({ theme }) => theme.utilities?.spacing?.["space-sm"]};
    p {
      transform: translateX(0);
    }
  }
`;

export const NavItems = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const LogOut = styled.p`
  font-size: 0.85rem;
  font-weight: bold;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;
