import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import getIcon from "utils/getIcon";
import ReportTableBody from "./reportTableBody";
import {
  ReportTableIcon,
  ReportTableTitle,
  ReportTableTitleColumn,
  ReportTableTitleWrapper,
  ReportTableTotal,
  ReportTableTotalColumn,
  ReportTableTotalColumnData,
  ReportTableTotalColumnCell,
  ReportTableWrapper,
  ReportTableColumnFiller,
} from "./reportTable.styles";

export type ReportTableRow = {
  title: string;
  icon: string;
  color: string;
  data: string[] | number[];
  total: string | number;
};

type ReportTableProps = {
  rows: ReportTableRow[];
  columnHeaders: string[];
};

const ReportTable: React.FC<ReportTableProps> = ({ rows, columnHeaders }) => {
  return (
    <ReportTableWrapper>
      <ReportTableTitleColumn>
        {rows.map(({ icon, title, color }) => (
          <ReportTableTitleWrapper key={title}>
            <ReportTableIcon color={color}>
              <FontAwesomeIcon icon={getIcon(icon)} color={color} size="sm" />
            </ReportTableIcon>
            <ReportTableTitle>{title}</ReportTableTitle>
          </ReportTableTitleWrapper>
        ))}
      </ReportTableTitleColumn>

      <ReportTableBody rows={rows} columnHeaders={columnHeaders} />

      <ReportTableTotalColumn>
        <ReportTableTotalColumnCell>Total</ReportTableTotalColumnCell>
        <ReportTableTotalColumnData>
          {rows.map(({ total }, i) => (
            <ReportTableTotal key={i}>{total}</ReportTableTotal>
          ))}
        </ReportTableTotalColumnData>
        <ReportTableColumnFiller />
      </ReportTableTotalColumn>
    </ReportTableWrapper>
  );
};

export default ReportTable;
