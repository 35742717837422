import {
  DataByAnalyticType,
  Filters,
  serializeDateRange,
  toDataByAnalyticTypeArray,
} from "hooks/globalContext";
import { DateRange, DateRangeBinWidthKey } from "utils/dateRange";
import ReportHeader from "components/reportHeader";
import ReportTable from "components/reportTable";
import { ReportTableWrapper } from "./configDrivenReportTable.styles";
import { MetadataMapper, useBusinessMappings } from "hooks/useBusinessMappings";
import {
  AnalyticsFormatFunction,
  useAnalyticsFormatting,
} from "hooks/useAnalyticsFormatting";
import { useEffect, useMemo, useState } from "react";
import { dateRangePresets } from "components/date-range/useDateRange";
import { useGlobalAnalytics } from "hooks/useGlobalAnalytics";
import ComponentPlaceholder from "components/componentPlaceholder";
import { ReportTableRow } from "components/reportTable/reportTable";

type ReportTableRowConfig = {
  analyticType: string;
  color?: string;
  icon?: string;
};

type ConfigDrivenReportTableProps = {
  config: {
    title: string;
    subtitle: string;
    table: ReportTableRowConfig[];
    dateRangeKey: string;
    filters: Filters;
    shouldInheritPageFilters?: boolean;
  };
  loading: boolean;
  pageFilters: Filters;
  key: number;
};

const ConfigDrivenReportTable: React.FC<ConfigDrivenReportTableProps> = (
  props
) => {
  //eslint-disable-next-line
  const dateRange = useMemo(
    dateRangePresets[props.config.dateRangeKey].getDateRange,
    [props.config.dateRangeKey]
  ) as DateRange;

  const [filters, setFilters] = useState(
    props.config.shouldInheritPageFilters
      ? props.pageFilters
      : props.config.filters
  );
  useEffect(() => {
    if (props.config.shouldInheritPageFilters) {
      setFilters(props.pageFilters);
    }
  }, [props.pageFilters, props.config.shouldInheritPageFilters]);

  const allDependencies = getAllDependencies(props.config.table, dateRange);
  const {
    loading: loadingDependencies,
    dependencyData,
    setCanFetch,
    setDependencies,
    dependencies,
  } = useGlobalAnalytics({
    dependencies: allDependencies,
    dateRange,
    filters,
  });

  const [totals, setTotals] = useState<{ [key: string]: number | string }>({});
  const [tableData, setTableData] = useState(toDataByAnalyticTypeArray({}));

  useEffect(() => {
    const currentDateRangeKey = serializeDateRange(dateRange);
    if (!(props.loading || loadingDependencies)) {
      setTotals(extractTotalsFromDependencies(dependencyData));
      setTableData(
        toDataByAnalyticTypeArray(dependencyData[currentDateRangeKey])
      );
    }
  }, [dependencyData, props.loading, loadingDependencies, dateRange]);
  useEffect(() => {
    if (!props.loading) {
      setCanFetch(true);
    } else {
      setCanFetch(false);
    }
  }, [props.loading, setCanFetch]);

  useEffect(() => {
    setDependencies(
      dependencies.map((dependency) => {
        return { ...dependency, filters };
      })
    );
    //eslint-disable-next-line
  }, [filters]);

  /** get headers -- headers are the bin names for this table */
  const columnHeaders = tableData[0]?.binNames || [];
  const { mapMetadata } = useBusinessMappings();
  const format = useAnalyticsFormatting();

  /**  */
  const dataTableRows = formatRows(
    tableData,
    props.config.table,
    mapMetadata,
    format,
    totals
  );

  const loading = props.loading || loadingDependencies;

  return (
    <ReportTableWrapper key={props.key}>
      <ReportHeader
        title={props.config.title}
        subtitle={props.config.subtitle}
      />
      {!loading ? (
        <ReportTable rows={dataTableRows} columnHeaders={columnHeaders} />
      ) : (
        <ComponentPlaceholder height={45 * (props.config.table.length + 1)} />
      )}
    </ReportTableWrapper>
  );
};

/**prepare the rows for the report table */
const formatRows = (
  data: DataByAnalyticType[],
  rowConfigs: ReportTableRowConfig[],
  mapper: MetadataMapper,
  format: AnalyticsFormatFunction,
  totals: { [key: string]: number | string }
) => {
  const rowProps = rowConfigs.map((rowConfig) => {
    /** find this rows primary analytic data */
    const values = data.find(
      (row) => row.analyticType === rowConfig.analyticType
    );
    const formattedData = (values?.data || []).map((row) =>
      format(row, values?.analyticType)
    );

    const metadata = mapper(rowConfig.analyticType);
    /** construct props for data table row component */
    const rowProps: ReportTableRow = {
      title: metadata.userFriendlyLabel,
      icon: rowConfig.icon ?? metadata.relatedIcon,
      color: rowConfig.icon ?? metadata.relatedColor,
      data: formattedData,
      total: getRowTotals(totals, rowConfig, format),
    };
    return rowProps;
  });
  return rowProps;
};

const getRowTotals = (
  totals: { [key: string]: number | string },
  rowConfig: ReportTableRowConfig,
  format: AnalyticsFormatFunction
) => {
  return format(totals[rowConfig.analyticType], rowConfig.analyticType, {
    showEmptyData: true,
  });
};
/**wrapper for the below generator to clean up the function signature */
const getAllDependencies = (
  rows: ReportTableRowConfig[],
  dateRange: DateRange,
  filters?: Filters
) => {
  return [...getAllDependenciesGenerator(rows, dateRange, filters)];
};
/**using a generator to get a nice flat data structure */
function* getAllDependenciesGenerator(
  rows: ReportTableRowConfig[],
  dateRange: DateRange,
  filters: Filters
) {
  for (const dependency of rows) {
    yield {
      analyticType: dependency.analyticType,
      dateRange: dateRange,
      filters,
    };
    yield {
      analyticType: dependency.analyticType,
      dateRange: { ...dateRange, binWidth: "total" as DateRangeBinWidthKey },
      filters,
    };
  }
}
const Factory = (props: ConfigDrivenReportTableProps) => ({
  Component: ({ key, loading, pageFilters }) =>
    ConfigDrivenReportTable({ ...props, key, pageFilters, loading }),
  getInitialDependencies: () => {
    return getAllDependencies(
      props.config.table,
      dateRangePresets[props.config.dateRangeKey].getDateRange(),
      props.config.shouldInheritPageFilters
        ? props.pageFilters
        : props.config.filters
    );
  },
});
export default Factory;
const extractTotalsFromDependencies = (dependencies) => {
  const totalKey = Object.keys(dependencies).find((key) => key.match("total"));
  const total = dependencies[totalKey];
  if (!total) return {};
  return Object.fromEntries(
    Object.entries(total).map(([key, value]) => {
      return [key, Object.values(value)?.[0] || 0];
    })
  );
};
