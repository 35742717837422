import React, { useEffect, useMemo, useState } from "react";
import { Wrapper } from "./styles";
import DataTable from "../../components/dataTable";
import { toDataByAnalyticTypeArray } from "../../hooks/globalContext";

import { useGlobalAnalytics } from "../../hooks/useGlobalAnalytics";
import { DataByAnalyticType, Filters } from "../../hooks/globalContext";
import { DateRange, DateRangeBinWidthKey } from "../../utils/dateRange";
import { DataTableRow } from "../../components/dataTable/types/dataTableRow";
import {
  dateRangePresets,
  RangeKey,
} from "../../components/date-range/useDateRange";
import { useBusinessMappings } from "hooks/useBusinessMappings";
import {
  AnalyticsFormatFunction,
  FormatType,
  useAnalyticsFormatting,
} from "hooks/useAnalyticsFormatting";
import DataTablePlaceholder from "components/dataTable/dataTablePlaceholder";
import { serializeDateRange } from "../../hooks/globalContext";
type DataTableRowConfig = {
  value: {
    dataKey: string;
  };
  tooltipText: string;
  secondaryValue?: DataTableRowConfig["value"];
  title?: string;
  formatType?: FormatType;
};

export type ConfigDrivenDataTableProps = {
  loading: boolean;
  config: {
    table: DataTableRowConfig[];
    initialRangeKey: RangeKey;
    filters: Filters;
    shouldInheritPageFilters: boolean;
    colorTheme?: string;
    hideTotalColumn?: boolean;
    rowHeader?: string;
    title?: string;
  };
  pageFilters: Filters;
  key?: string;
};
export const ConfigDrivenDataTable: React.FC<ConfigDrivenDataTableProps> = (
  props
) => {
  //eslint-disable-next-line
  const dateRange = useMemo(
    dateRangePresets[props.config.initialRangeKey].getDateRange,
    [props.config.initialRangeKey]
  );

  const [filters, setFilters] = useState(
    props.config.shouldInheritPageFilters
      ? props.pageFilters
      : props.config.filters
  );

  const allDependencies = getAllDependencies(props.config.table, dateRange);
  const {
    loading: loadingDependencies,
    dependencyData,
    dependencies,
    setDependencies,
    setCanFetch,
  } = useGlobalAnalytics({
    dependencies: allDependencies,
    dateRange,
    filters,
  });
  useEffect(() => {
    if (props.config.shouldInheritPageFilters) {
      setTotals(extractTotalsFromDependencies(dependencyData));
      setFilters(props.pageFilters);
    }
  }, [
    props.pageFilters,
    props.config.shouldInheritPageFilters,
    dependencyData,
  ]);

  const [totals, setTotals] = useState<{ [key: string]: number | string }>({});
  const [tableData, setTableData] = useState(toDataByAnalyticTypeArray({}));

  useEffect(() => {
    const currentDateRangeKey = serializeDateRange(dateRange);
    if (!(props.loading || loadingDependencies)) {
      setTotals(extractTotalsFromDependencies(dependencyData));
      setTableData(
        toDataByAnalyticTypeArray(dependencyData[currentDateRangeKey])
      );
    }
  }, [dependencyData, props.loading, loadingDependencies, dateRange]);
  useEffect(() => {
    if (!props.loading) {
      setCanFetch(true);
    } else {
      setCanFetch(false);
    }
  }, [props.loading, setCanFetch]);

  useEffect(() => {
    setDependencies(
      dependencies.map((dependency) => {
        return { ...dependency, filters };
      })
    );
    //eslint-disable-next-line
  }, [filters]);

  /** get headers -- headers are the bin names for this table */
  const dataTableColumnHeaders = tableData[0]?.binNames || [];
  const { mapLabel } = useBusinessMappings();
  const format = useAnalyticsFormatting();

  /**  */
  const dataTableRows = formatRows(
    tableData,
    props.config.table,
    mapLabel,
    format,
    totals
  );

  const loading = props.loading || loadingDependencies;

  return (
    <Wrapper>
      {props.config.title && <h1>{props.config.title}</h1>}

      {!loading ? (
        <DataTable
          rows={dataTableRows}
          rowHeader={props.config.rowHeader ?? "SOURCE"}
          columnHeaders={dataTableColumnHeaders.map(mapLabel)}
          hideTotalColumn={props.config?.hideTotalColumn}
          customColorTheme={props.config?.colorTheme}
          showTableBorder
        />
      ) : (
        <DataTablePlaceholder numRows={props.config.table.length} />
      )}
    </Wrapper>
  );
};

/**prepare the  */
const formatRows = (
  data: DataByAnalyticType[],
  rowConfigs: DataTableRowConfig[],
  mapper: (string: string) => string = (s) => s,
  format: AnalyticsFormatFunction,
  totals: { [key: string]: number | string }
) => {
  const rowProps = rowConfigs.map((rowConfig) => {
    /** find this rows primary analytic data */
    const values = data.find(
      (row) => row.analyticType === rowConfig.value.dataKey
    );
    /** find this rows secondary analytic type if exists */
    const secondaryValues = data.find(
      (row) => row.analyticType === rowConfig?.secondaryValue?.dataKey
    );
    const rowData = (values?.data || []).map((dataPoint, i) => {
      /**calculate this cells value and secondary value if exists */
      return secondaryValues?.data?.[i]
        ? { value: dataPoint, secondaryValue: secondaryValues.data[i] }
        : {
            value: dataPoint,
          };
    });

    const formattedData = rowData.map((row) => {
      let res = {
        value: format(row.value, values?.analyticType),
        secondaryValue: null,
      };
      if (rowConfig.secondaryValue) {
        let formatted = format(row.value, rowConfig.secondaryValue.dataKey, {
          formatType: "percentage",
        });
        res.secondaryValue = formatted === "---" ? null : formatted;
      }

      return res;
    });
    /** construct props for data table row component */
    const rowProps: DataTableRow = {
      //@ts-ignore
      title: mapper(rowConfig.value.dataKey),
      data: formattedData,
      total: getRowTotals(totals, rowConfig, format),
      tooltipText: rowConfig.tooltipText,
    };
    return rowProps;
  });
  return rowProps;
};

const getRowTotals = (
  totals: { [key: string]: number | string },
  rowConfig: DataTableRowConfig,
  format: AnalyticsFormatFunction
) => {
  return {
    value: format(totals[rowConfig.value.dataKey], rowConfig.value.dataKey, {
      showEmptyData: true,
    }),
    secondaryValue: !!rowConfig.secondaryValue
      ? format(
          totals[rowConfig.secondaryValue.dataKey],
          rowConfig.secondaryValue.dataKey,
          {
            showEmptyData: true,
            formatType: "percentage",
          }
        )
      : null,
  };
};
/**wrapper for the below generator to clean up the function signature */
const getAllDependencies = (
  rows: DataTableRowConfig[],
  dateRange: DateRange
) => {
  return [...getAllDependenciesGenerator(rows, dateRange)];
};
/**using a generator to get a nice flat data structure */
function* getAllDependenciesGenerator(
  rows: DataTableRowConfig[],
  dateRange: DateRange
) {
  for (const dependency of rows) {
    yield {
      analyticType: dependency.value.dataKey,
      dateRange: dateRange,
    };
    yield {
      analyticType: dependency.value.dataKey,
      dateRange: { ...dateRange, binWidth: "total" as DateRangeBinWidthKey },
    };
    if (dependency.secondaryValue) {
      yield {
        analyticType: dependency.secondaryValue.dataKey,
        dateRange: dateRange,
      };
      yield {
        analyticType: dependency.secondaryValue.dataKey,
        dateRange: { ...dateRange, binWidth: "total" as DateRangeBinWidthKey },
      };
    }
  }
}
const Factory = (props: ConfigDrivenDataTableProps) => ({
  Component: ({ key, loading, pageFilters }) =>
    ConfigDrivenDataTable({ ...props, key, pageFilters, loading }),
  getInitialDependencies: () => {
    return getAllDependencies(
      props.config.table,
      dateRangePresets[props.config.initialRangeKey].getDateRange()
    );
  },
});
export default Factory;
const extractTotalsFromDependencies = (dependencies) => {
  const totalKey = Object.keys(dependencies).find((key) => key.match("total"));
  const total = dependencies[totalKey];
  if (!total) return {};
  return Object.fromEntries(
    Object.entries(total).map(([key, value]) => {
      return [key, Object.values(value)?.[0] || 0];
    })
  );
};
