import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  JourneyItem,
  MetricCompleted,
  MetricConnector,
  MetricIcon,
  MetricWrapper,
} from "./faithJourney.styles";

type FaithJourneyItemProps = {
  title: string;
  icon: any;
  color: string;
  active: boolean;
  completedDate?: string;
  itemType: "first" | "middle" | "last";
};

const FaithJourneyItem: React.FC<FaithJourneyItemProps> = ({
  title,
  icon,
  color,
  active,
  completedDate,
  itemType,
}) => {
  return (
    <JourneyItem>
      <h3>{title}</h3>
      <MetricWrapper active={active}>
        <MetricConnector color={itemType !== "first" ? color : null} />
        <MetricIcon color={color}>
          <FontAwesomeIcon icon={icon} size="2x" />
        </MetricIcon>
        <MetricConnector color={itemType !== "last" ? color : null} />
      </MetricWrapper>
      <MetricCompleted active={active}>
        {active ? completedDate ?? "---" : "Not yet completed"}
      </MetricCompleted>
    </JourneyItem>
  );
};

export default FaithJourneyItem;
