import styled from "styled-components";

export const HighLevelMetricsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.utilities.spacing["space-sm"]};
`;

export const HighLevelMetricsHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
