import React from "react";
import { AnalyticEngineTypes } from "./types";
import { Partner, PartnerConfig } from "./types";
import { mergeObjectsDeeply } from "./globalContext";
import { Dependency, Filters, serializeFilters } from ".";
type Action =
  | { type: "fetchPartnerStart" }
  | { type: "fetchPartnerEnd"; partner: Partner }
  | { type: "fetchConfigForPartnerStart" }
  | { type: "fetchConfigForPartnerEnd"; partnerConfig: PartnerConfig }
  | { type: "setActiveView"; index: number }
  | {
      type: "addNewAnalyticsDataEnd";
      newAnalyticsData: GlobalState["analyticsData"];
    }
  | {
      type: "addNewAnalyticsDataStart";
      dependencies: Dependency[];
    }
  | {
      type: "setPageFilters";
      viewIndex: number;
      pageUrl: string;
      filters: Filters;
    };
export type GlobalState = {
  /**
   * the current partner and their associated data
   */
  partner: Partner | null;
  /**
   * true if loading partner data currently
   */
  loadingPartner: boolean;
  /**
   * the config data for the partner that will be used for
   * rendering their customized pages/views/components
   */
  partnerConfig: PartnerConfig;
  /**
   * true if loading partner config data currently
   */
  loadingPartnerConfig: boolean;
  /**
   * the index of the current active view in partnerConfig.views
   */
  activeView: number;
  /**
   * the central location for all analytics to be held
   * keyed by
   *  - startdate
   *  - endDate
   *  - AnalyticType
   *  - BinName
   */
  analyticsData: {
    [key: string /**alphabetized filters */]: {
      [
        key: AnalyticEngineTypes["Range"]["StartDate"] /** the start date in ms-since-epoch notation as a number */
      ]: {
        [
          key: AnalyticEngineTypes["Range"]["EndDate"] /** the end date in ms-since-epoch notation as a number */
        ]: {
          [
            key: AnalyticEngineTypes["Range"]["BinName"] /** bin width of the anlytic */
          ]: {
            [
              key: AnalyticEngineTypes["AnalyticType"] /** the analytic type */
            ]: {
              [key: AnalyticEngineTypes["Range"]["BinName"]]: number;
            };
          };
        };
      };
    };
  };

  /**
   * this can uniquely identify data by filters, type and date range.
   * this data will allow us to make requestDependencies idempotent
   * and avoid unecessary re-renders
   */
  analyticsLoadingStates: {
    [key: string /**alphabetized filters */]: {
      [
        key: AnalyticEngineTypes["Range"]["StartDate"] /** the start date in ms-since-epoch notation as a number */
      ]: {
        [
          key: AnalyticEngineTypes["Range"]["EndDate"] /** the end date in ms-since-epoch notation as a number */
        ]: {
          [
            key: AnalyticEngineTypes["Range"]["BinName"] /** bin width of the anlytic */
          ]: {
            [
              key: AnalyticEngineTypes["AnalyticType"] /** the analytic type */
            ]: {
              loading: boolean;
            };
          };
        };
      };
    };
  };
  pageFilters: {
    [key: string]: {
      [key: string]: Filters;
    };
  };
};
export const globalInitialState: GlobalState = {
  partner: null,
  loadingPartner: false,
  loadingPartnerConfig: false,
  partnerConfig: null,
  activeView: 0,
  analyticsData: {},
  analyticsLoadingStates: {},
  pageFilters: {},
};
export const globalReducer: React.Reducer<GlobalState, Action> = (
  state,
  action
) => {
  switch (action.type) {
    case "fetchPartnerStart":
      return {
        ...state,
        loadingPartner: true,
      };
    case "fetchPartnerEnd":
      return {
        ...state,
        partner: action.partner,
        loadingPartner: false,
      };
    case "fetchConfigForPartnerStart":
      return {
        ...state,
        loadingPartnerConfig: true,
      };
    case "fetchConfigForPartnerEnd":
      return {
        ...state,
        partnerConfig: action.partnerConfig,
        loadingPartnerConfig: false,
      };
    case "setActiveView":
      return {
        ...state,
        activeView: action.index,
      };
    case "addNewAnalyticsDataStart":
      const newLoadingStates: GlobalState["analyticsLoadingStates"] =
        action.dependencies.reduce((acc, current) => {
          const filters = serializeFilters(current.filters);
          const start = current.dateRange.startDate.date.getTime();
          const end = current.dateRange.endDate.date.getTime();
          const bin = current.dateRange.binWidth;
          return {
            ...acc,
            [filters]: {
              ...acc?.[filters],
              [start]: {
                ...acc?.[filters]?.[start],
                [end]: {
                  ...acc?.[filters]?.[start]?.[end],
                  [bin]: {
                    ...acc?.[filters]?.[start]?.[end]?.[bin],
                    [current.analyticType]: {
                      ...acc?.[filters]?.[start]?.[end]?.[bin]?.[
                        current.analyticType
                      ],
                      loading:
                        acc?.[filters]?.[start]?.[end]?.[bin]?.[
                          current.analyticType
                        ] || true,
                    },
                  },
                },
              },
            },
          } as GlobalState["analyticsLoadingStates"];
        }, state.analyticsLoadingStates);
      return {
        ...state,
        analyticsLoadingStates: {
          ...state.analyticsLoadingStates,
          ...newLoadingStates,
        },
      };
    case "addNewAnalyticsDataEnd":
      return {
        ...state,
        analyticsData: mergeObjectsDeeply(
          state.analyticsData,
          action.newAnalyticsData
        ),
      };
    case "setPageFilters":
      return {
        ...state,
        pageFilters: {
          [action.viewIndex]: {
            [action.pageUrl]: action.filters,
          },
        },
      };
    default:
      console.error(
        "unknown action recieved. Action.type: " + action?.["type"]
      );
      break;
  }
};
