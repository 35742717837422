type IconMapping = {
  [color: string]: {
    x: number;
    y: number;
    width: number;
    height: number;
    mask: boolean;
  };
};

const generateIconMapping = (
  width: number,
  height: number,
  colorKeyMapping = colorMapping
) => {
  const itemSize = 256;
  let mapping: IconMapping = {};

  for (let i = 0; i < width; i++) {
    for (let j = 0; j < height; j++) {
      mapping[colorKeyMapping[i * width + j].toUpperCase()] = {
        x: j * itemSize,
        y: i * itemSize,
        width: itemSize,
        height: itemSize,
        mask: false,
      };
    }
  }
  return mapping;
};

const colorMapping = [
  "#071C54",
  "#fda533",
  "#22814f",
  "#2957CC",
  "#4267B2",
  "#bf3100",
  "#97ead2",
  "#00a5cf",
  "#ee7674",
];

export default generateIconMapping;
