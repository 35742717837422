import {
  ReportHeaderDivider,
  ReportHeaderTitle,
  ReportHeaderWrapper,
} from "./reportHeader.styles";

type ReportHeaderProps = {
  title: string;
  subtitle: string;
};

const ReportHeader: React.FC<ReportHeaderProps> = ({ title, subtitle }) => (
  <ReportHeaderWrapper>
    <ReportHeaderDivider />
    <ReportHeaderTitle>
      <h2>{title}</h2>
      <p>{subtitle}</p>
    </ReportHeaderTitle>
    <ReportHeaderDivider />
  </ReportHeaderWrapper>
);

export default ReportHeader;
