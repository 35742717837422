import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useReducer,
  useState,
} from "react";
import { DateRange } from "../../utils/dateRange";
import { getDependencies } from "./analyticsClient";
import { SerializedDateRange, Filters } from "./types";
import { getViewsForPartner, getSpecificPartner } from "./configClient";
import {
  globalInitialState,
  globalReducer,
  GlobalState,
} from "./globalReducer";
import { PartnerConfig } from "hooks/globalContext/types";
import { ViewConfig } from ".";

//#region split contexts
export type UsePartnerReturnType = Pick<
  ReturnType<typeof useGlobalContext>,
  "partner" | "fetchConfigForPartner" | "fetchPartner"
>;
const partnerContext = createContext<UsePartnerReturnType>(
  {} as UsePartnerReturnType
);
export const PartnerContext: React.FC = (props) => {
  const state = useGlobal();
  const value = useMemo(() => {
    const partner = state.partner;
    const fetchConfigForPartner = state.fetchConfigForPartner;
    const fetchPartner = state.fetchPartner;
    return { partner, fetchConfigForPartner, fetchPartner };
  }, [state.partner, state.fetchConfigForPartner, state.fetchPartner]);
  return (
    <partnerContext.Provider value={value}>
      {props.children}
    </partnerContext.Provider>
  );
};
export const usePartner: () => UsePartnerReturnType = () => {
  const partner = useContext(partnerContext);
  return partner;
};
export type UseAnalyticsDataReturnType = Pick<
  ReturnType<typeof useGlobalContext>,
  "analyticsData" | "analyticsLoadingStates" | "requestDependencies"
>;
const analyticsDataContext = createContext<UseAnalyticsDataReturnType>(
  {} as UseAnalyticsDataReturnType
);
export const AnalyticsDataContext: React.FC = (props) => {
  const state = useGlobal();
  const value = useMemo(() => {
    const analyticsData = state.analyticsData;
    const analyticsLoadingStates = state.analyticsLoadingStates;
    const requestDependencies = state.requestDependencies;

    return { analyticsData, analyticsLoadingStates, requestDependencies };
  }, [
    state.analyticsData,
    state.analyticsLoadingStates,
    state.requestDependencies,
  ]);
  return (
    <analyticsDataContext.Provider value={value}>
      {props.children}
    </analyticsDataContext.Provider>
  );
};
export const useAnalyticsData: () => UseAnalyticsDataReturnType = () => {
  const view = useContext(analyticsDataContext);
  return view;
};
export type UseViewReturnType = Pick<
  ReturnType<typeof useGlobalContext>,
  "setActiveView" | "activeView"
> & {
  views: ViewConfig[];
  currentView: ViewConfig;
};
const viewContext = createContext<UseViewReturnType>({} as UseViewReturnType);
export const ViewContext: React.FC = (props) => {
  const state = useGlobal();
  const value = useMemo(() => {
    const setActiveView = state.setActiveView;
    const activeView = state.activeView;
    const views = state?.partnerConfig?.views || [];
    return { setActiveView, activeView, views };
  }, [state.activeView, state.setActiveView, state.partnerConfig]);
  const [currentView, setCurrentView] = useState<ViewConfig>({} as ViewConfig);
  useEffect(() => {
    setCurrentView(value.views[value.activeView]);
  }, [value.activeView, value.views]);
  return (
    <viewContext.Provider value={{ currentView, ...value }}>
      {props.children}
    </viewContext.Provider>
  );
};
export const useView: () => UseViewReturnType = () => {
  const view = useContext(viewContext);
  return view;
};
//#endregion
const globalContext = createContext<ReturnType<typeof useGlobalContext>>(
  {} as ReturnType<typeof useGlobalContext>
);

/**
 * the context provider component to mount above any consumers in the component tree
 */
export const GlobalContext: React.FC = (props) => {
  const state = useGlobalContext();
  return (
    <globalContext.Provider value={state}>
      <AnalyticsDataContext>
        <PartnerContext>
          <ViewContext>{props.children}</ViewContext>
        </PartnerContext>
      </AnalyticsDataContext>
    </globalContext.Provider>
  );
};
const useGlobalContext = () => {
  const [{ analyticsLoadingStates, ...state }, dispatch] = useReducer(
    globalReducer,
    globalInitialState
  );
  /**
   * @description gets the partner data from the config server
   * @param name the name of the partner to fetch
   */
  const fetchPartner = useCallback(
    async (name: string) => {
      dispatch({ type: "fetchPartnerStart" });
      const partner = await getSpecificPartner(name);
      dispatch({ type: "fetchPartnerEnd", partner });
    },
    [dispatch]
  );
  /**
   * @description gets the views for the specified config server
   * @param partnerId the uuid of the partner to fetch config for
   */
  const fetchConfigForPartner = useCallback(
    async (partnerId: string) => {
      dispatch({ type: "fetchConfigForPartnerStart" });
      const partnerConfig = await getViewsForPartner(partnerId);
      let sortedPartnerConfig = sortPartnerConfig(partnerConfig);
      dispatch({
        type: "fetchConfigForPartnerEnd",
        partnerConfig: sortedPartnerConfig,
      });
    },
    [dispatch]
  );
  /**
   *
   * @param index the index of the view in state.partnerconfig.views to set as the current one
   */
  const setActiveView = useCallback(
    (index) => {
      dispatch({ type: "setActiveView", index });
    },
    [dispatch]
  );
  /**
   * maps through the dependencies given and returns true if the resource is in the data store
   * @param dependencies
   */
  const checkDependenciesFulfillment = useCallback(
    (
      dependencies: Dependency[],
      analyticsData: GlobalState["analyticsData"]
    ): CheckDependenciesFulfullmentResults => {
      const fufillmentResults = dependencies.map(
        ({ analyticType, dateRange, filters }) => {
          const startTime = dateRange.startDate.date.getTime();
          const endTime = dateRange.endDate.date.getTime();
          const exists =
            !!analyticsData?.[serializeFilters(filters)]?.[startTime]?.[
              endTime
            ]?.[dateRange.binWidth]?.[analyticType];
          return {
            analyticType,
            exists,
            filters: serializeFilters(filters),
            startTime,
            endTime,
            dateRange,
          };
        }
      );
      return fufillmentResults;
    },
    []
  );
  const checkDependenciesRequested = useCallback(
    (
      dependencies: Dependency[],
      analyticsLoadingData: GlobalState["analyticsLoadingStates"]
    ): CheckDependenciesRequestedResults => {
      const fufillmentResults = dependencies.map(
        ({ analyticType, dateRange, filters }) => {
          const startTime = dateRange.startDate.date.getTime();
          const endTime = dateRange.endDate.date.getTime();
          const requested =
            !!analyticsLoadingData?.[serializeFilters(filters)]?.[startTime]?.[
              endTime
            ]?.[dateRange.binWidth]?.[analyticType];
          return {
            analyticType,
            requested,
            filters: serializeFilters(filters),
            startTime,
            endTime,
            dateRange,
          };
        }
      );
      return fufillmentResults;
    },
    []
  );

  const requestDependencies = useCallback(
    async (dependencies: Dependency[]) => {
      const dependencyIsNotAlreadyRequested = (dependency: Dependency) => {
        const filters = serializeFilters(dependency.filters);
        const start = dependency.dateRange.startDate.date.getTime();
        const end = dependency.dateRange.endDate.date.getTime();
        const alreadyRequested =
          !!analyticsLoadingStates?.[filters]?.[start]?.[end]?.[
            dependency.dateRange.binWidth
          ]?.[dependency.analyticType];
        return !alreadyRequested;
      };
      const dependenciesToFetch = dependencies.filter(
        dependencyIsNotAlreadyRequested
      );
      if (dependenciesToFetch.length > 0) {
        dispatch({
          type: "addNewAnalyticsDataStart",
          dependencies,
        });
        const newAnalytics = await getDependencies(dependencies);
        const mappedResponses = newAnalytics.map((response) => {
          return {
            [serializeFilters(response.filters)]: {
              [new Date(response.range.startDate).getTime()]: {
                [new Date(response.range.endDate).getTime()]: {
                  [response.range.binWidth]: {
                    ...response.analytics,
                  },
                },
              },
            },
          };
        }) as unknown as GlobalState["analyticsData"][];
        const stateDataStructure: GlobalState["analyticsData"] =
          mergeObjectsDeeply(...mappedResponses);
        dispatch({
          type: "addNewAnalyticsDataEnd",
          newAnalyticsData: stateDataStructure,
        });
      }
    },
    [analyticsLoadingStates]
  );
  return {
    ...state,
    fetchPartner,
    fetchConfigForPartner,
    setActiveView,
    checkDependenciesFulfillment,
    checkDependenciesRequested,
    serializeFilters,
    serializeDateRange,
    requestDependencies,
    analyticsLoadingStates,
  };
};
/**
 *
 * @param dateRange
 * @returns a serialized date range string to be used in the data store
 */
export const serializeDateRange: (dateRange: DateRange) => SerializedDateRange =
  ({ startDate, endDate, binWidth }) =>
    `${startDate.dateOption}:::${endDate.dateOption}:::${binWidth}` as SerializedDateRange;
/**
 *
 * @param filters the filters to be used with the analytics engine
 * @returns a stringified version of the filters POJO
 */
export const serializeFilters = (filters: Filters) => {
  /**declare our delimiters for when we join array values into one string */
  const delimiters = {
    withinIndividualFilter: ":",
    betweenFilters: "::",
  };
  /**sort keys alphabetically, then sort the value arrays alphabetically */
  const sortedKeys = Object.keys(filters || {})
    .map((key) => key.toLowerCase())
    .sort();
  if (sortedKeys.length === 0) {
    return "none";
  }
  /**serialize each individual filter property and its values */
  const serializedFiltersArray = sortedKeys.reduce((acc, currKey) => {
    const sortedValues = filters[currKey].map((filterValue) =>
      filterValue.toLowerCase()
    );
    const individualSerializedFilter = [currKey, ...sortedValues].join(
      delimiters.withinIndividualFilter
    );
    return [...acc, individualSerializedFilter];
  }, []);
  /** join everything into one long string */
  const filterString = serializedFiltersArray.join(delimiters.betweenFilters);
  return filterString;
};
/**
 * the public hook to use inside components
 */
export const useGlobal = () => useContext(globalContext);
export type CheckDependenciesFulfullmentResults = {
  analyticType: string;
  exists: boolean;
}[];
export type CheckDependenciesRequestedResults = {
  analyticType: string;
  requested: boolean;
}[];
export type Dependency = {
  analyticType: string;
  dateRange: DateRange;
  filters: Filters;
};

/**
 * Simple object check.
 */
export function isObject(item) {
  return item && typeof item === "object" && !Array.isArray(item);
}
/**
 * Deep assign two objects.
 */
export function assignDeep(output: Object, ...inputs: Object[]) {
  if (!inputs.length) return output;
  const currentInput = inputs.shift();
  if (isObject(output) && isObject(currentInput)) {
    for (const key in currentInput) {
      if (isObject(currentInput[key])) {
        if (!output[key]) output[key] = {};
        assignDeep(output[key], currentInput[key]);
      } else {
        Object.assign(output, { [key]: currentInput[key] });
      }
    }
  } else {
  }
  return assignDeep(output, ...inputs);
}

export function mergeObjectsDeeply<T>(...objects: T[]) {
  const output = {} as Partial<T>;
  assignDeep(output, ...objects);
  return output as T;
}

const sortPartnerConfig = (config: PartnerConfig) => {
  for (let view of config.views || []) {
    for (let page of view.pages) {
      page.displayComponentConfigs.sort((a, b) => a.precedence - b.precedence);
    }
    view.pages.sort((a, b) => a.precedence - b.precedence);
  }
  config.views.sort((a, b) => a.precedence - b.precedence);
  return config;
};
