import React from "react";
import { createGlobalStyle } from "styled-components";

export type Theme = {
  name: string;
  colors?: { [key: string]: string };
  utilities?: {
    layout?: { [key: string]: string };
    spacing?: { [key: string]: string };
    borders?: { [key: string]: string };
    breakpoints?: { [key: string]: string };
  };
};
const lightTheme: Theme = {
  name: "light",
  colors: {
    "ui-background": "#ebeef7",
    "primary-light": "#2957CC",
    "primary-dark": "#1f356e",
    "primary-darker": "#071C54",
    "text-default": "#1f356e",
    "text-dark": "#1f356e",
    "text-link": "#1448cc",
    "secondary-default": "#FDA533",
    "secondary-dark": "#F29513",
    "ui-gray": "#ebeef5",
    "ui-gray-lighter": "#f7faff",
    "ui-gray-dark": "#d7ddea",
    "ui-gray-darker": "#dadde2",
    "ui-blue": "#E5EDFF",
    "ui-blue-dark": "#dde4f7",
    "table-line": "rgba(31, 53, 110, 0.2)",
    "table-line-light": "rgba(255, 255, 255, 0.2)",
    scrollbar: "#B8BFD1",
    metric: "#3251A6",
    "ui-alert": "#C92E24",
    "ui-alert-dark": "#C90C00",
    "box-shadow-color": "rgba(0, 0, 0, 0.05)",
    "box-shadow-sm-color": "rgba(22, 38, 77, 0.2)",
    "box-shadow-light-color": "rgba(22, 38, 77, 0.1)",
  },
  utilities: {
    layout: {
      "max-width": "1920px",
      "box-shadow": "0px 4px 8px",
      "box-shadow-sm": "0px 2px 4px",
      "box-shadow-light": "0px 2px 4px",
    },
    spacing: {
      "space-xs": "0.5rem",
      "space-sm": "1rem",
      "space-md": "2rem",
      "space-lg": "4rem",
      "space-xl": "6rem",
      "space-page": "8rem",
    },
    borders: {
      "border-radius-sm": "0.25rem",
      "border-radius-md": "0.5rem",
      "border-radius-lg": "1rem",
    },
    breakpoints: {
      xs: "576px",
      sm: "768px",
      md: "992px",
      lg: "1200px",
      xl: "1440px",
    },
  },
};
const darkTheme: Theme = {
  ...lightTheme,
  name: "dark",
  colors: {
    ...lightTheme.colors,
    "ui-background": "#192C59",
    "primary-dark": "#1f356e",
    "primary-light": "#2662f9",
    "text-default": "#ffffff",
    "text-dark": "#1f356e",
    "text-link": "#fd940a",
    "secondary-default": "#fd940a",
    "secondary-dark": "#f29513",
    "ui-gray-lighter": "#2A468C",
    "ui-gray": "#1f366b",
    "ui-gray-dark": "#192C59",
    "ui-gray-darker": "#dadde2",
    "ui-blue": "#192C59",
    "ui-blue-dark": "#16264e",
    "ui-alert": "#C92E24",
    "ui-alert-dark": "#C90C00",
    "box-shadow-color": "rgba(0, 0, 0, 0.15)",
    "box-shadow-sm-color": "rgba(22, 38, 77, 0.4)",
    "box-shadow-light-color": "rgba(22, 38, 77, 0.3)",
  },
};
export const GlobalStyles = createGlobalStyle`
  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }
  html {
    height: 100%;
    -webkit-font-smoothing: antialiased;
  }
  body {
    font-family: 'Lato', sans-serif;
    line-height: 1.6;
    overflow-x: hidden;
  }
  div#root {
    background-color:${({ theme }) => theme?.colors?.["ui-background"]};
    color: ${({ theme }) => theme?.colors?.["text-default"]} ;
    min-height: 100vh;
  }
  a {
    text-decoration: none;
  }
  ul {
    list-style: none;
  }
  img {
    width: 100%;

  }
// Container
.container {
  max-width: ${({ theme }: { theme: Theme }) =>
    theme.utilities.layout["max-width"]};
  margin: auto;
  overflow: hidden;
  padding: 0 2rem;
  margin-top: 6rem;
  margin-bottom: 3rem;
  box-shadow: ${({ theme }: { theme: Theme }) =>
    theme.utilities.layout["box-shadow"] +
    ", " +
    theme.colors["box-shadow-color"]};
}

// Text Styles
.text-xl {
  font-size: 4rem;
  line-height: 1.2;
  margin-bottom: 1rem;
  font-weight: 700;
}

.text-lg {
  font-size: 3rem;
  line-height: 1.2;
  margin-bottom: 1rem;
  font-weight: 700;
}

.text-md {
  font-size: 1.5rem;
  margin-bottom: 1rem;
  font-weight: 400;

  &.bold {
    font-weight: 700;
  }
}

.text-sm-md {
  font-size: 1.125rem;
  margin-bottom: 0.75rem;
  font-weight: 400;

  &.bold {
    font-weight: 700;
  }
}

.text-sm {
  font-size: 1rem;
  // margin-bottom: 0.5rem;
  font-weight: 400;

  &.bold {
    font-weight: 700;
  }
}

.text-xs {
  font-size: 0.875rem;
  margin-bottom: 0.1rem;
  font-weight: 400;

  &.bold {
    font-weight: 700;
  }
}

.label {
  font-weight: 700;
  font-size: 0.9rem;
}
`;
export const StoryGlobalStyleDecorator = (Story) => (
  <div>
    <GlobalStyles />
    <Story />
  </div>
);
export const themes = {
  light: lightTheme,
  dark: darkTheme,
};
