import Card from "components/card";
import styled from "styled-components";

export const MetricsRow = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: ${({ theme }) => theme.utilities.spacing["space-lg"]};
`;

export const HighLevelMetricWrapper = styled(Card.Body)`
  overflow: visible;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.utilities.spacing["space-md"]};
`;

export const GraphWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: ${({ theme }) => theme.utilities.spacing["space-sm"]};
  margin-bottom: ${({ theme }) => theme.utilities.spacing["space-sm"]};

  .recharts-wrapper {
    position: relative;
  }

  svg:not(:root) {
    overflow: visible;
  }

  h3 {
    width: 100%;
  }

  .label-wrapper {
    padding: 2px 4px;
    width: 100%;
    height: 100%;
    display: flex;

    &.align-right {
      justify-content: flex-end;
    }

    .graph-label {
      width: fit-content;
      height: fit-content;
      background-color: ${({ theme }) => theme.colors["ui-gray-lighter"]};
      border-radius: ${({ theme }) =>
        theme.utilities.borders["border-radius-sm"]};
      padding: 5px 10px;
      box-shadow: 0px 2px 4px #00000050;

      p {
        font-size: 0.75rem;
        opacity: 0.8;
      }
    }
  }
`;

export const GraphKey = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 ${({ theme }) => theme.utilities.spacing["space-sm"]};
`;

const graphKeyBorder = "#1f356e25";

export const GraphKeyItem = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: auto 1fr auto;
  gap: ${({ theme }) => theme.utilities.spacing["space-sm"]};
  align-items: center;
  border-top: 1px solid ${graphKeyBorder};
  padding: ${({ theme }) => theme.utilities.spacing["space-xs"]} 0;

  &:last-child {
    border-bottom: 1px solid ${graphKeyBorder};
  }

  h5 {
    font-size: 1rem;
  }
`;

export const GraphKeyColor = styled.div<{ color: string }>`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: ${({ color }) => color};
`;
