import { SeekerProfile } from "components/seekerProfile/types";
import { Filters } from "hooks/globalContext";
import { getProfiles } from "hooks/globalContext/churchConnectClient";
import { useEffect, useState } from "react";
import CountryService from "services/country-service";
import { mapLegacyDateRange } from "utils/dateRange";
import { ProfileMapFilters } from "./useProfileMapFilters";

export type ProfileType = "default" | "trinity";

const useProfileMapData = (
  selectedCountry: string,
  filters: ProfileMapFilters,
  inheritedFilters: Filters,
  loadingFilters: boolean
) => {
  const [loading, setLoading] = useState(false);
  const [profiles, setProfiles] = useState([]);
  const [filteredProfiles, setFilteredProfiles] = useState([]);

  useEffect(() => {
    if (!selectedCountry) {
      setFilteredProfiles([...profiles]);
    } else {
      setFilteredProfiles(
        profiles.filter((profile) => profile.country === selectedCountry)
      );
    }
  }, [profiles, selectedCountry]);

  const getRequestFilters = () => {
    let result = { digital: !!inheritedFilters.digital };
    let type = filters.currentFilters["KPI Type"]?.toLowerCase();
    if (type !== "showing all") {
      result["type"] = type;
    }
    let filterMapping = mapLegacyDateRange(
      filters.currentFilters["Date Range"]
    );
    if (filterMapping) {
      result["startDate"] = filterMapping.startDate.date.toISOString();
    }

    return result;
  };

  const fetchMapData = async () => {
    setLoading(true);
    let profiles = await getProfiles(getRequestFilters());
    setProfiles(CountryService.parseLocationData(parseProfiles(profiles)));
    setLoading(false);
  };

  const parseProfiles = (profiles: SeekerProfile[]) => {
    let filtered = profiles.filter((x) => !!x.status && !!x.coords);

    return filtered.map((x) => {
      for (let key of Object.keys(x.metrics)) {
        x.metrics[key] = new Date(x.metrics[key]).toLocaleDateString("en-US", {
          year: "numeric",
          month: "long",
          day: "numeric",
        });
      }
      return x;
    });
  };

  useEffect(() => {
    if (!loadingFilters) {
      fetchMapData();
    }
    //eslint-disable-next-line
  }, [filters.currentFilters, loadingFilters]);

  return {
    loading,
    profiles,
    filteredProfiles,
  };
};

export default useProfileMapData;
