import { useAuth } from "context/authContext";
import { useCallback, useMemo } from "react";
import totalCardRowFactory from "../totalCardRow";
import { PageConfig } from "../../hooks/globalContext";
import AreaGraphWithDataTableFactory from "../areaGraphWithDataTable";
import { PageWrapper, PageWrapperNoStyles } from "./styles";
import PageHeader from "components/pageHeader";
import { usePageFilters } from "hooks/usePageFilters";
import { useGlobalAnalytics } from "hooks/useGlobalAnalytics";
import ConfigDrivenMap from "../configDrivenMap";
import BubbleRow from "../bubbleRow";
import ConfigDrivenHighLevelMetricsFactory from "configDrivenComponents/configDrivenHighLevelMetrics";
import ConfigDrivenDataTableFactory from "../configDrivenDataTable";
import { Filters } from "hooks/globalContext";
import ConfigDrivenReportTableFactory from "configDrivenComponents/configDrivenReportTable";
import DataCardRowFactory from "configDrivenComponents/dataCardRow";
import ConfigDrivenDataTableListFactory from "../configDrivenScrollingDataTable/dataTableList";

const ComponentFactory = (componentAccessorKey: string, props) => {
  switch (componentAccessorKey) {
    case "AreaGraphWithDataTable":
      return AreaGraphWithDataTableFactory(props);
    case "DataTable":
      return ConfigDrivenDataTableFactory(props);
    case "TotalCardRow":
      return totalCardRowFactory(props);
    case "BubbleRow":
      return BubbleRow(props);
    case "ImpactMetricsBySurvey":
      return ConfigDrivenDataTableListFactory(props);
    case "HighLevelMetrics":
      return ConfigDrivenHighLevelMetricsFactory(props);
    case "DataCardRow":
      return DataCardRowFactory(props);
    case "ReportTable":
      return ConfigDrivenReportTableFactory(props);
    case "ConfigDrivenMap":
      return {
        /** change to props to pass filters in on all components */
        Component: ({ key, ...rest }) =>
          ConfigDrivenMap({ ...props, key, ...rest }),
        getInitialDependencies: () => [],
      };
    default:
      return {
        Component: () => <div key="error"> Error: config not available</div>,
        getInitialDependencies: () => [],
      };
  }
};
export type PageProps = { config: PageConfig; viewFilters?: Filters };

export const Page = ({ config, viewFilters }: PageProps) => {
  const { logout, currentUser } = useAuth();
  const { updateCategories, filters } = usePageFilters({
    filterOptionCategories: config?.filterOptionCategories || [],
    viewFilters,
  });
  const setupComponentFactories = useCallback(() => {
    return config.displayComponentConfigs.map(
      ({ componentAccessorKey, config }) => {
        const factory = ComponentFactory(componentAccessorKey, {
          config,
        });
        return { ...factory };
      }
    );
  }, [config.displayComponentConfigs]);
  const componentFactories = useMemo(setupComponentFactories, [
    setupComponentFactories,
  ]);
  const setupDependencies = useCallback(() => {
    return componentFactories.flatMap((factory) =>
      factory.getInitialDependencies()
    );
  }, [componentFactories]);
  const dependencies = useMemo(setupDependencies, [setupDependencies]);

  const { loading } = useGlobalAnalytics({
    dependencies,
    isPage: true,
  });
  const WrapperComponent = config.ignoreBasePageStyles
    ? PageWrapperNoStyles
    : PageWrapper;

  return (
    <WrapperComponent>
      {config.shouldDisplayFilters && (
        <PageHeader
          title={config.title}
          name={currentUser.name.first}
          logoutFunction={logout}
          filterOptionCategories={config.filterOptionCategories}
          filterUpdateFunction={updateCategories}
          currentFilters={filters}
        />
      )}
      {componentFactories.map(({ Component }, i) => {
        return Component({ key: i, loading, pageFilters: filters });
      })}
    </WrapperComponent>
  );
};
export default Page;
