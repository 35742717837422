import { useCallback, useState } from "react";
import { PieChart, Pie, Sector, Cell } from "recharts";
import { GraphWrapper } from "./highLevelMetrics.styles";

type HighLevelMetricGraphProps = {
  colorConfig: string[];
  title: string;
  total: string;
  types: {
    title: string;
    value: number;
    hideTooltip?: boolean;
  }[];
};

const HighLevelMetricGraph: React.FC<HighLevelMetricGraphProps> = ({
  title,
  total,
  types,
  colorConfig,
}) => {
  let typesFormatted: any = types
    .filter((type) => {
      return (
        type.value !== null && type.value != undefined && !isNaN(type.value)
      );
    })
    .map(({ title, value }: any) => {
      return { title, value: Number(value) };
    });

  const [activeIndex, setActiveIndex] = useState(0);
  const onPieEnter = useCallback(
    (_, index) => {
      setActiveIndex(index);
    },
    [setActiveIndex]
  );

  if (!typesFormatted.length || typesFormatted.every((x) => x.value === 0)) {
    typesFormatted = [{ title: "No Data", value: 1, hideTooltip: true }];
    colorConfig = ["#D3D3D350"];
  }

  return (
    <>
      <GraphWrapper>
        <h3>{title}</h3>
        <PieChart width={400} height={250}>
          <Pie
            activeIndex={activeIndex}
            activeShape={(props) => renderActiveShape(props, total)}
            data={typesFormatted}
            cx={200}
            cy={125}
            innerRadius={70}
            outerRadius={90}
            fill="#8884d8"
            dataKey="value"
            onMouseEnter={onPieEnter}
          >
            {types.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={colorConfig[index % colorConfig.length]}
              />
            ))}
          </Pie>
        </PieChart>
      </GraphWrapper>
    </>
  );
};

const renderActiveShape = (props: any, total: string) => {
  const RADIAN = Math.PI / 180;
  const {
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    startAngle,
    endAngle,
    fill,
    payload,
    percent,
  } = props;

  const sin = Math.sin(-RADIAN * midAngle);
  const cos = Math.cos(-RADIAN * midAngle);
  const sx = cx + (outerRadius + 10) * cos;
  const sy = cy + (outerRadius + 10) * sin;
  const mx = cx + (outerRadius + 30) * cos;
  const my = cy + (outerRadius + 30) * sin;
  const ex = mx + (cos >= 0 ? 1 : -1) * 22;
  const ey = my;

  return (
    <g>
      <text
        x={cx}
        y={cy}
        dy={8}
        textAnchor="middle"
        fill="#1F356E"
        fontSize={getGraphTotalSize(total)}
        fontWeight="bold"
        fontFamily="Lato"
      >
        {total}
      </text>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
      <Sector
        cx={cx}
        cy={cy}
        startAngle={startAngle}
        endAngle={endAngle}
        innerRadius={outerRadius + 6}
        outerRadius={outerRadius + 10}
        fill={fill}
      />
      {!payload.hideTooltip && (
        <>
          <path
            d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`}
            stroke={fill}
            fill="none"
          />
          <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
          <foreignObject
            x={ex + (cos >= 0 ? 1 : -1) * 12 + (cos < 0 ? -150 : 0)}
            y={ey - 27.5}
            width="150"
            height="55"
          >
            {/* @ts-ignore */}
            <div className={`label-wrapper ${cos < 0 && "align-right"}`}>
              <div className="graph-label">
                <h5>{payload.title}</h5>
                <p> {`${(percent * 100).toFixed(2)}%`}</p>
              </div>
            </div>
          </foreignObject>
        </>
      )}
    </g>
  );
};

const getGraphTotalSize = (label: string) => {
  if (label.length > 9) {
    return "1.25rem";
  } else if (label.length > 6) {
    return "1.5rem";
  }

  return "2rem";
};

export default HighLevelMetricGraph;
