import { dateRangePresets, RangeKey } from "components/date-range/useDateRange";
import { Filters } from "hooks/globalContext";
import { DateRange } from "utils/dateRange";
import React, { useEffect, useState } from "react";
import ScrollingDataTableContainer from "../../components/dataTable/scrollingDataTableContainer";
import { Dependency } from "hooks/globalContext";
import { Dropdowns } from "../areaGraphWithDataTable/styles";
import DateRangeDropDown from "components/date-range";
import { useGlobalAnalytics } from "hooks/useGlobalAnalytics";
import { DataTableProps } from "components/dataTable/dataTable";
import DataTablePlaceholder from "components/dataTable/dataTablePlaceholder";
import Card from "components/card";
export type ConfigDrivenDataTableListConfig = {
  initialRangeKey: RangeKey;
  shouldInheritPageFilters: boolean;
  filters: Filters;
};
export type ConfigDrivenDataTableListProps = {
  pageFilters: Filters;
  config: ConfigDrivenDataTableListConfig;
  loading: boolean;
};
type SurveyType = {
  analyticType: `surveys${number}${"M" | "D" | "W"}`;
  name: `${number} ${"Week" | "Day" | "Month"}`;
  totalType: string;
};
const surveyTypes: SurveyType[] = [
  { analyticType: "surveys3D", name: "3 Day", totalType: "surveysSent3D" },
  { analyticType: "surveys2W", name: "2 Week", totalType: "surveysSent2W" },
  { analyticType: "surveys2M", name: "2 Month", totalType: "surveysSent2M" },
  { analyticType: "surveys6M", name: "6 Month", totalType: "surveysSent6M" },
  { analyticType: "surveys12M", name: "12 Month", totalType: "surveysSent12M" },
];
const surveyQuestions = [
  "baptized",
  "inPerson",
  "attending",
  "multiplying",
  "salvations",
  "readingBible",
];
type SurveyReturnType = {
  /** the survey name */
  [key: string]: {
    /** the bin name */
    [key: string]: {
      /** the survey questions names and their responses */
      [key: string]: string | number;
    };
  };
};

export const ConfigDrivenDataTableList: React.FC<ConfigDrivenDataTableListProps> =
  (props) => {
    const [dateRange, setDateRange] = useState<DateRange>(
      dateRangePresets[props.config.initialRangeKey].getDateRange
    );
    const [filters, setFilters] = useState(
      props.config.shouldInheritPageFilters
        ? props.pageFilters
        : props.config.filters
    );
    const [tableData, setTableData] = useState({});
    useEffect(() => {
      if (props.config.shouldInheritPageFilters) {
        setFilters(props.pageFilters);
      }
    }, [props.pageFilters, props.config.shouldInheritPageFilters]);
    /** get initial dependencies */
    /**
     * [X] all survey types
     * [X] get data for selected date range
     * [ ] zip disparate analytics into different data structure
     * [ ] insert data
     * [ ] handle loading state with placeholder
     */
    const allDependencies = getAllDependencies(surveyTypes, dateRange, filters);
    /**
     * for this component, we'll want to update the date range for
     * all dependencies unilaterally when the date range changes
     */
    useEffect(() => {
      if (dateRange) {
        setDependencies(
          dependencies.map((dependency) => {
            return { ...dependency, dateRange: dateRange };
          })
        );
      }
      //eslint-disable-next-line
    }, [dateRange]);
    useEffect(() => {
      setDependencies(
        dependencies.map((dependency) => {
          return { ...dependency, filters };
        })
      );
      //eslint-disable-next-line
    }, [filters]);
    const { loading, dependencyData, dependencies, setDependencies } =
      useGlobalAnalytics({
        dependencies: allDependencies,
        dateRange,
        filters,
        isPage: true,
      });

    /** recalculate tableData based off of dependency data */
    useEffect(() => {
      //@ts-ignore
      setTableData(
        dependencyDataToTableData(
          Object.values(dependencyData)?.[0] as unknown as SurveyReturnType
        )
      );
    }, [dependencyData]);
    return (
      <Card.Wrapper>
        <Card.Body>
          <Dropdowns>
            <DateRangeDropDown
              initialRangeKey={props.config.initialRangeKey}
              handleDateRangeChange={(range) => setDateRange(range)}
            />
          </Dropdowns>
          {loading ? (
            DataTablePlaceholder({ numRows: surveyQuestions.length + 1 })
          ) : (
            <ScrollingDataTableContainer
              data={tableData}
            ></ScrollingDataTableContainer>
          )}
        </Card.Body>
      </Card.Wrapper>
    );
  };
const getAllDependencies = (
  types: SurveyType[],
  dateRange: DateRange,
  filters: Filters
): Dependency[] => {
  const dependencies: Dependency[] = types.flatMap(
    ({ analyticType, totalType }) => [
      {
        analyticType,
        filters,
        dateRange,
      },
      {
        analyticType: totalType,
        filters,
        dateRange,
      },
    ]
  );
  return dependencies;
};
const dependencyDataToTableData: (data: SurveyReturnType) => {
  [key: string]: DataTableProps;
} = (data) => {
  const getDataForBinName = (bin) => {
    const getRow = (bin, question) => {
      const rowData = surveyTypes.map((type) => ({
        value: data[type.analyticType][bin][question],
      }));
      return {
        title: question,
        /** map survey types  and pull out relevant data */
        data: rowData,
        total: rowData.reduce((acc, { value }) => acc + (value as number), 0),
      };
    };
    const rows = [
      (() => {
        const rowData = surveyTypes.map((type) => {
          return { value: data[type.totalType][bin] };
        });
        return {
          title: "Surveys Sent",
          data: rowData,
          total: rowData.reduce(
            (acc, { value }) => acc + (value as unknown as number),
            0
          ),
        };
      })(),
      ...surveyQuestions.map((question) => getRow(bin, question)),
    ];
    return {
      rows,
      columnHeaders: surveyTypes.map((type) => type.name),
      noScroll: true,
      rowHeader: "METRIC",
    };
  };
  const tableData = Object.keys(Object.values(data || {})?.[0] || {})
    .reverse()
    .reduce((acc, bin) => {
      return { ...acc, [bin]: getDataForBinName(bin) };
    }, {});
  return tableData;
};
const Factory = (props) => ({
  Component: (key) => ConfigDrivenDataTableList({ ...props, key }),
  getInitialDependencies: () => [],
});
export default Factory;
