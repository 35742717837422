import React from "react";
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { TooltipWrapper } from "./styles";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
const Tooltip = ({ text }) => {
  let [showTooltip, setShowTooltip] = useState(false);
  return (
    <TooltipWrapper>
      <FontAwesomeIcon
        icon={faQuestionCircle as IconProp}
        onMouseEnter={() => setShowTooltip(true)}
        onMouseLeave={() => setShowTooltip(false)}
      />
      {showTooltip && <div className="tooltip-text">{text}</div>}
    </TooltipWrapper>
  );
};

export default Tooltip;
