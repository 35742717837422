import styled from "styled-components";
import ComponentPlaceholder from "../componentPlaceholder";

export type DataCardPlaceholderProps = {};

const Wrapper = styled.div`
  width: 375px;
  border-radius: ${({ theme }) => theme?.utilities.borders["border-radius-lg"]};
`;

const DataCardPlaceholder = () => (
  <Wrapper>
    <ComponentPlaceholder height={225} />
  </Wrapper>
);
export default DataCardPlaceholder;
