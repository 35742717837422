import { SeekerProfile } from "components/seekerProfile/types";
import getApiUrl from "utils/getApiUrl";

const apiServerAddress = getApiUrl(
  process.env.REACT_APP_CHURCH_CONNECT_API,
  "http://localhost:5000/api/v1",
  true
);

type GetProfilesProps = {
  digital?: boolean;
  startDate?: string;
  endDate?: string;
  type?: string;
};

export const getProfiles = async (props: GetProfilesProps = {}) => {
  const options = { queryParams: props };
  return makeGetRequest("/seekers/profiles", options) as Promise<
    SeekerProfile[]
  >;
};

type GetRequestOptionalParams = {
  queryParams?: { [key: string]: any };
};

const makeGetRequest: <T>(
  resource: string,
  options?: GetRequestOptionalParams
) => Promise<T> = async (resource, options) => {
  const init: RequestInit = {
    method: "get",
    mode: "cors",
    cache: "no-cache",
  };
  const url = new URL(apiServerAddress + resource);
  if (options?.queryParams) {
    Object.entries(options.queryParams).forEach(([key, value]) => {
      url.searchParams.append(key, value);
    });
  }
  const response = await fetch(url.toString(), init);
  return await response.json();
};
