import React from "react";
import {
  GraphControlWrapper,
  GraphDropdown,
  GraphWrapper,
} from "./areaGraph.styles";
import {
  AreaChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Area,
  ResponsiveContainer,
} from "recharts";
import AreaGraphTooltip from "./areaGraphTooltip";
import { useBusinessMappings } from "hooks/useBusinessMappings";
export type AreaGraphProps = {
  /**
   * the height of the area graph
   */
  height?: number;
  /**
   * the number of pixels per data point that will get the necessary
   * width of the graph
   */
  graphWidthScale?: number;
  /**
   * the data to display from
   */
  data: { [key: string]: number | string }[];
  /**
   * the key to access from each object in the data array
   */
  activeDataKey: string;
  /**
   *  the label to use in the tooltip on mouse hover
   */
  tooltipLabel: string;

  formatTooltipValue: (value: string) => string;

  dropdownProps: {
    options: string[];
    value: string;
    onChange: (value: string) => void;
  };
};
const AreaGraph: React.FC<AreaGraphProps> = (props) => {
  const { mapLabel, mapColor } = useBusinessMappings();

  const graphColor = mapColor(props.activeDataKey, "#2957CC");

  return (
    <GraphControlWrapper>
      <GraphDropdown {...props.dropdownProps} mapper={mapLabel} />

      <GraphWrapper>
        <ResponsiveContainer height={props.height}>
          <AreaChart
            height={props.height}
            data={props.data}
            margin={{
              top: 10,
              right: 60,
              left: 30,
              bottom: 0,
            }}
          >
            <CartesianGrid strokeDasharray="2 5" />
            <XAxis
              dataKey="key"
              interval="preserveStart"
              tickCount={1 * props.data.length}
              height={50}
              tick={<AxisTick />}
            />
            <YAxis
              domain={["auto", "auto"]}
              tickCount={4}
              tickFormatter={(value) => props.formatTooltipValue(value)}
            />
            <Tooltip
              formatter={(value: string) => [
                props.formatTooltipValue(value),
                props.tooltipLabel,
              ]}
              content={AreaGraphTooltip}
            />
            <Area
              type="monotone"
              dataKey={props.activeDataKey}
              stroke={graphColor}
              strokeWidth={2}
              fill={graphColor}
              fillOpacity={0.85}
            />
          </AreaChart>
        </ResponsiveContainer>
      </GraphWrapper>
    </GraphControlWrapper>
  );
};
const AxisTick = ({
  x,
  y,
  payload,
}: {
  x?: number;
  y?: number;
  payload?: { value: number };
}) => (
  <g transform={`translate(${x},${(y || 0) + 10})`}>
    <text x={0} y={0} dy={16} textAnchor="middle" fill="#1f356e">
      {payload.value}
    </text>
  </g>
);
export default AreaGraph;
