import MapSidebar from "../mapSidebar";
import { MapKeyItemColor, ProfileMapKey, ProfileMapWrapper } from "./styles";
import InteractiveMap from "../interactive-map";
import React, { useState } from "react";
import useDashboardMode from "./hooks/useDashboardMode";
import useProfileMapData from "./hooks/useProfileMapData";
import useProfileMapLayers from "./hooks/useProfileMapLayers";
import Metric from "components/metric";
import useProfileMapFilters, {
  SidebarProps,
} from "./hooks/useProfileMapFilters";
import ProfileList from "./profileList";
import { SeekerProfile as SeekerProfileType } from "../seekerProfile/types";
import SeekerProfile from "../seekerProfile";
import { Filters } from "hooks/globalContext";
import { ViewportProps } from "react-map-gl";
import { useAnalyticsFormatting } from "hooks/useAnalyticsFormatting";
import { useBusinessMappings } from "hooks/useBusinessMappings";
import getIcon from "utils/getIcon";

type ProfileMapProps = {
  config: {
    title: string;
    sidebar: SidebarProps;
    initialViewport?: Partial<ViewportProps>;
    kpiTypes: string[];
    journeyConfig: string[];
  };
  pageFilters: Filters;
  loading: boolean;
};

const ProfileMap: React.FC<ProfileMapProps> = ({
  config,
  pageFilters,
  loading,
}) => {
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedProfile, setSelectedProfile] =
    useState<SeekerProfileType>(null);

  const filters = useProfileMapFilters(config.kpiTypes, config.sidebar);
  const mapData = useProfileMapData(
    selectedCountry,
    filters,
    pageFilters,
    loading
  );
  const {
    dashboardMode,
    toggleDashboardMode,
    setDashboardMode,
    leaving,
    setLeaving,
  } = useDashboardMode(mapData.profiles, selectedCountry, setSelectedProfile);

  const { mapColor, mapIcon, mapMetadata, mapLabel } = useBusinessMappings();
  const currentType = filters.currentFilters["KPI Type"];

  const metricLabel =
    currentType !== "Showing All" ? mapLabel(currentType) : "Showing All";

  const renderTooltip = ({ object }) => {
    if (!object || !object.coords) {
      return null;
    }

    const { status, firstName, nickname } = object;

    const metadata = mapMetadata(status);

    return {
      html: `<div class="profile-tooltip">
               <h5>${nickname ?? firstName}</h5>
               <p style="color: ${metadata.relatedColor};">
                 ${metadata.userFriendlyLabel}
               </p>
             </div>`,
      style: {
        backgroundColor: "transparent",
      },
    };
  };

  const onProfileClick = (info: any) => {
    if (info.index >= 0) {
      let profile = mapData.profiles[info.index];
      setSelectedProfile(profile);
    }
  };

  const format = useAnalyticsFormatting();

  const layers = useProfileMapLayers(
    mapData.profiles,
    onProfileClick,
    mapColor
  );

  const countrySelected = !!selectedCountry;

  return (
    <ProfileMapWrapper>
      <InteractiveMap
        title={config.title}
        loading={mapData.loading}
        layers={[layers]}
        selectedCountry={selectedCountry}
        setSelectedCountry={setSelectedCountry}
        renderTooltip={renderTooltip}
        selectedItem={selectedProfile}
        dashboardMode={dashboardMode}
        initialViewport={config.initialViewport}
      >
        <Metric
          icon={getIcon(mapIcon(currentType, "chart-bar"))}
          title={`${
            !countrySelected ? "World" : selectedCountry
          }: ${metricLabel}`}
          value={format(mapData.filteredProfiles.length, null, {
            showEmptyData: true,
          })}
          onClose={countrySelected ? () => setSelectedCountry(null) : null}
        />
        <ProfileMapKey>
          {config.kpiTypes.map((type) => {
            const metadata = mapMetadata(type);
            return (
              <React.Fragment key={type}>
                <div className="map-key-item">
                  {metadata.userFriendlyLabel}:
                </div>
                <MapKeyItemColor color={metadata.relatedColor} />
              </React.Fragment>
            );
          })}
        </ProfileMapKey>
      </InteractiveMap>
      <MapSidebar
        {...filters}
        dashboardMode={dashboardMode}
        toggleDashboardMode={toggleDashboardMode}
      >
        <ProfileList
          filteredProfiles={mapData.filteredProfiles}
          setSelectedProfile={setSelectedProfile}
        />
      </MapSidebar>

      {!!selectedProfile && (
        <SeekerProfile
          dashboardMode={dashboardMode}
          setDashboardMode={setDashboardMode}
          leaving={leaving}
          setLeaving={setLeaving}
          selectedProfile={selectedProfile}
          setSelectedProfile={setSelectedProfile}
          journeyConfig={config.journeyConfig}
        />
      )}
    </ProfileMapWrapper>
  );
};

export default ProfileMap;
