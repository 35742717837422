import React, { MouseEventHandler } from "react";
import styled, { StyledComponent } from "styled-components";

const ButtonBase = styled.button`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 0.6rem 1.25rem;
  font-family: "Lato", sans-serif;
  font-weight: 700;
  color: ${({ theme }) => theme.colors?.["text-default"]};
  border: none;
  cursor: pointer;
  font-size: 1.125rem;
  // font-size: 1rem;

  outline: none;
  border-radius: ${({ theme }) => theme.utilities.borders["border-radius-md"]};
  transition: all 0.2s;
  text-align: center;
  line-height: 1.5;
  white-space: nowrap;
  box-shadow: 0px 2px 4px rgba(22, 38, 77, 0.2);

  .btn + .btn {
    margin-right: 0.5rem;
  }

  &:disabled {
    opacity: 0.8;
    cursor: not-allowed;
  }

  &:hover {
    filter: brightness(0.9);
  }
`;

const PrimaryButton = styled(ButtonBase)`
  color: #fff;
  background-color: ${({ theme }) => theme.colors?.["primary-light"]};
`;

const SecondaryButton = styled(ButtonBase)`
  color: #22293b;
  background-color: ${({ theme }) => theme.colors?.["secondary-default"]};
  border: 2px solid ${({ theme }) => theme.colors?.["secondary-dark"]};
`;

const GrayButton = styled(ButtonBase)`
  color: ${({ theme }) => theme.colors?.["primary-dark"]};
  background-color: ${({ theme }) => theme.colors?.["ui-gray-light"]};
`;

type ButtonType = "default" | "primary" | "secondary" | "gray";
type ButtonProps = {
  type: ButtonType;
  onClick: Function;
};
const discernButtonType = (type: ButtonType) => {
  switch (type) {
    case "default":
      return ButtonBase;
    case "primary":
      return PrimaryButton;
    case "secondary":
      return SecondaryButton;
    case "gray":
      return GrayButton;
  }
};
const Button: React.FC<ButtonProps> = ({
  type,
  onClick,
  children,
  ...rest
}) => {
  const ButtonComponent: StyledComponent<"button", any, {}, never> =
    discernButtonType(type);
  return (
    <ButtonComponent onClick={onClick as MouseEventHandler} {...rest}>
      {children}
    </ButtonComponent>
  );
};

export default Button;
