import React from "react";
import { ThemeProvider } from "styled-components";
import { Theme } from "../../theme";
import DataTableData from "./dataTableData/dataTableData";
import DataTableFixedColumn from "./dataTableFixedColumn/dataTableFixedColumn";
import { overrideColorTheme, Table } from "./styles";
import { DataTableRow } from "./types/dataTableRow";
import { fixedColumnType } from "./types/fixedColumnType";

export interface DataTableProps {
  rows: DataTableRow[];
  columnHeaders: string[];
  rowHeader: string;
  customColorTheme?: string;
  noScroll?: boolean;
  hideTotalColumn?: boolean;
  showTableBorder?: boolean;
}

const DataTable = ({
  rows,
  rowHeader,
  customColorTheme,
  noScroll,
  columnHeaders,
  hideTotalColumn,
  showTableBorder,
}: DataTableProps) => {
  return (
    <ThemeProvider
      theme={(theme: Theme) => overrideColorTheme(theme, customColorTheme)}
    >
      <Table
        className={`${noScroll && "no-scroll"} ${
          showTableBorder && "show-border"
        }`}
      >
        <DataTableFixedColumn
          title={rowHeader}
          rows={rows}
          columnType={fixedColumnType.metric}
        />
        <DataTableData
          rows={rows}
          noScroll={noScroll}
          columnHeaders={columnHeaders}
        />
        {!hideTotalColumn && (
          <DataTableFixedColumn
            title="Totals"
            rows={rows}
            columnType={fixedColumnType.total}
          />
        )}
      </Table>
    </ThemeProvider>
  );
};

export default DataTable;
