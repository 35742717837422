import { useState } from "react";
import {
  FilterOptionProps,
  MapFilterTitle,
  MapSidebarFiltersProps,
} from "../../mapSidebar/mapSidebarFilters/mapSidebarFilters";

export type SidebarProps = {
  defaultDateRange?: string;
  defaultDropdownKey: string;
  availableTypes: string[];
};
export type ReportingMapFilters = Omit<
  MapSidebarFiltersProps,
  "dashboardMode" | "toggleDashboardMode"
>;
type ReportingMapFiltersHook = (
  sidebarProps: SidebarProps
) => ReportingMapFilters;

const useReportingMapFilters: ReportingMapFiltersHook = (sidebarProps) => {
  const filterOptions = generateFilterOptionsFromSidebarProps(sidebarProps);

  const [currentFilters, setCurrentFilters] = useState(
    generateDefaultFiltersFromFilterOptions(filterOptions)
  );

  const onFiltersChange = (title: MapFilterTitle, value: string) => {
    setCurrentFilters({
      ...currentFilters,
      [title]: value,
    });
  };

  const onFiltersClear = () => {
    setCurrentFilters(generateDefaultFiltersFromFilterOptions(filterOptions));
  };

  return {
    filterOptions,
    currentFilters,
    onFiltersChange,
    onFiltersClear,
  };
};

export default useReportingMapFilters;

const generateFilterOptionsFromSidebarProps = (
  props: SidebarProps
): FilterOptionProps[] => {
  let result = [] as FilterOptionProps[];
  result = [
    generateDateRangeFilterOptions(props.defaultDateRange),
    {
      title: "KPI Type",
      defaultDropdownOption: props.defaultDropdownKey,
      dropdownOptions: props.availableTypes,
    },
  ];
  return result;
};

const generateDateRangeFilterOptions = (
  defaultRange?: string
): FilterOptionProps => {
  return {
    title: "Date Range",
    defaultDropdownOption: defaultRange ?? "Last 7 Days",
    dropdownOptions: [
      "Last 7 Days",
      "Last 30 Days",
      "Last 12 Weeks",
      "Last 12 Months",
      "Year To Date",
      "All Time",
    ],
  };
};

const generateDefaultFiltersFromFilterOptions = (
  filterOptions: FilterOptionProps[]
) => {
  let filters = {} as MapSidebarFiltersProps["currentFilters"];
  for (let option of filterOptions) {
    filters[option.title] = option.defaultDropdownOption;
  }
  return filters;
};
