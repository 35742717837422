import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import {
  faChevronRight,
  faChevronLeft,
} from "@fortawesome/free-solid-svg-icons";
import {
  Container,
  ErrorContainer,
  Base,
  Title,
  SubTitle,
  Text,
  Input,
  InputContainer,
  Row,
  Label,
  Submit,
  Dropdown,
  TextArea,
  MobileWizard,
} from "./form";

export default function Form({ children, ...restProps }) {
  return <Container {...restProps}>{children}</Container>;
}

Form.ErrorContainer = function FormErrorContainer({ children, ...restProps }) {
  return <ErrorContainer {...restProps}>{children}</ErrorContainer>;
};

Form.Base = function FormBase({ children, ...restProps }) {
  return <Base {...restProps}>{children}</Base>;
};

Form.Title = function FormTitle({ children, ...restProps }) {
  return <Title {...restProps}>{children}</Title>;
};

Form.SubTitle = function FormSubTitle({ children, ...restProps }) {
  return <SubTitle {...restProps}>{children}</SubTitle>;
};

Form.Text = function FormText({ children, ...restProps }) {
  return <Text {...restProps}>{children}</Text>;
};

Form.Input = function FormInput({ type = "text", ...restProps }) {
  return <Input type={type} {...restProps} />;
};

Form.TextArea = function FormTextArea({ ...restProps }) {
  return <TextArea {...restProps}></TextArea>;
};

Form.InputContainer = function FormInputContainer({ children, ...restProps }) {
  return <InputContainer {...restProps}>{children}</InputContainer>;
};

Form.Row = function FormRow({ children, connected = false, ...restProps }) {
  return (
    <Row className={connected ? "connected" : ""} {...restProps}>
      {children}
    </Row>
  );
};

Form.Checkbox = function FormCheckbox({
  value,
  checked,
  onChange,
  name,
  id,
  label,
  disabled,
  children,
  ...restProps
}) {
  return (
    <label className={`checkbox ${checked ? "active" : ""}`}>
      {label}
      <input
        className="form__checkbox"
        id={id}
        type="checkbox"
        name={name}
        value={value}
        disabled={disabled}
        checked={checked}
        onChange={onChange}
        {...restProps}
      >
        {children}
      </input>
    </label>
  );
};

Form.RadioButton = function FormRadioButton({ children, ...restProps }) {
  const {
    onClick,
    id,
    type,
    name,
    value,
    label,
    disabled = false,
    checked,
  } = restProps;

  return (
    <label
      className={`radioButton ${checked ? "active" : ""}`}
      htmlFor={id}
      disabled={disabled}
    >
      {label}
      <input
        style={{ height: 0, width: 0, opacity: 0 }}
        type={type}
        name={name}
        id={id}
        value={value}
        onClick={onClick}
        checked={checked}
      >
        {children}
      </input>
    </label>
  );
};

Form.Label = function FormLabel({ children, ...restProps }) {
  return <Label {...restProps}>{children}</Label>;
};

Form.Submit = function FormSubmit({ children, ...restProps }) {
  return <Submit type="submit" {...restProps} />;
};

Form.Dropdown = function FormDropdown({
  options,
  value,
  onChange,
  dropdownClassName,
  mapper = (s) => s,
  ...restProps
}) {
  return (
    <Dropdown className={dropdownClassName}>
      <select
        value={value}
        onChange={(e) => onChange(e.target.value)}
        {...restProps}
      >
        {options.map((option) => (
          <option value={option} key={option}>
            {mapper(option)}{" "}
          </option>
        ))}
      </select>
      <FontAwesomeIcon icon={faChevronDown} />
    </Dropdown>
  );
};

Form.MobileWizard = function FormMobileWizard({
  step,
  name,
  onNextClick,
  onPrevClick,
  nextDisabled,
  prevDisabled,
  ...restProps
}) {
  return (
    <MobileWizard {...restProps}>
      <button
        className={`btn ${
          prevDisabled ? "btn-primary-outline" : "btn-primary"
        }`}
        disabled={prevDisabled}
        onClick={onPrevClick}
      >
        <FontAwesomeIcon icon={faChevronLeft} />
      </button>
      <div>
        <h4>Step {step}</h4>
        <h3>{name}</h3>
      </div>
      <button
        className={`btn ${
          nextDisabled ? "btn-primary-outline" : "btn-primary"
        }`}
        disabled={nextDisabled}
        onClick={onNextClick}
      >
        <FontAwesomeIcon icon={faChevronRight} />
      </button>
    </MobileWizard>
  );
};
