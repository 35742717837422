import styled from "styled-components";

export const FaithJourneyWrapper = styled.div`
  width: 100%;
  padding: ${({ theme }) => theme?.utilities.spacing["space-sm"]}
    ${({ theme }) => theme?.utilities.spacing["space-md"]}
    ${({ theme }) => theme?.utilities.spacing["space-md"]};
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${({ theme }) => theme?.utilities.spacing["space-md"]};
`;

export const JourneyItemsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2px;
`;

export const JourneyItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${({ theme }) => theme?.utilities.spacing["space-sm"]};
`;

export const MetricWrapper = styled.div<{ active: boolean }>`
  display: flex;
  align-items: center;
  opacity: ${({ active }) => (active ? 1 : 0.25)};
`;

export const MetricConnector = styled.div<{ color?: string }>`
  width: 75px;
  height: 5px;
  background-color ${({ color }) => color};
`;

export const MetricIcon = styled.div<{ color: string }>`
  width: 100px;
  height: 100px;
  border-radius: 50%;
  border: 5px solid ${({ color }) => color};
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ color }) => color};
`;

export const MetricCompleted = styled.p<{ active: boolean }>`
  font-size: 0.9rem;
  opacity: ${({ active }) => (active ? 1 : 0.25)};
  color: #333;
`;

export const Logo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
  p {
    font-size: 0.75rem;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    font-weight: bold;
    color: #00000095;
  }

  img {
    width: 150px;
  }
`;
