import { useEffect, useRef, useState } from "react";
import ScrollContainer from "react-indiana-drag-scroll";
import DataTableDataRow from "./dataTableDataRow";
import { TableData, TableCell, TableRow } from "./styles";
import { DataTableRow } from "../types/dataTableRow";
interface DataTableDataProps {
  rows: DataTableRow[];
  columnHeaders: string[];
  noScroll?: boolean;
}

const DataTableData = ({
  rows,
  columnHeaders,
  noScroll,
}: DataTableDataProps) => {
  const scrollContainerRef = useRef<HTMLElement>(null);

  const [isGrabbing, setIsGrabbing] = useState(false);

  useEffect(() => {
    if (scrollContainerRef.current && !noScroll) {
      scrollContainerRef.current.scrollLeft =
        scrollContainerRef.current.scrollWidth;
    }
    //eslint-disable-next-line
  }, [scrollContainerRef, rows]);

  return (
    <TableData className={noScroll && "no-scroll"}>
      <ScrollContainer
        className="scroll-container"
        vertical={false}
        hideScrollbars={false}
        onStartScroll={() => setIsGrabbing(true)}
        onEndScroll={() => setIsGrabbing(false)}
        style={{
          cursor: isGrabbing ? "grabbing" : "grab",
        }}
        innerRef={scrollContainerRef}
      >
        <TableRow className="column-headers">
          {columnHeaders.map((header) => (
            <TableCell key={header}>{header}</TableCell>
          ))}
        </TableRow>
        {rows.map((row, i) => (
          <DataTableDataRow key={i} row={row} />
        ))}
      </ScrollContainer>
    </TableData>
  );
};

export default DataTableData;
