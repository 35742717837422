import { IconLayer } from "@deck.gl/layers";
import { HexagonLayer } from "@deck.gl/aggregation-layers";
import generateIconMapping from "utils/generateIconMapping";
import generateHexColors from "utils/generateHexColors";

type LayerCallbackObject = { type: string; coords: number[] };

const getHexSizeScale = (zoomLevel: number) =>
  Math.pow(0.65, Math.ceil(zoomLevel - 1));
const iconMapping = generateIconMapping(3, 3);

const useReportingMapLayers = (
  data: any[],
  mapView: string,
  colorType: string,
  useHexScaling: boolean,
  zoomLevel: number,
  colorMapper: (type: string) => string
) => {
  const hexColors = generateHexColors(colorMapper(colorType));

  const pinLayer = {
    id: "data",
    data,
    pickable: true,
    billboard: false,
    iconAtlas: "icon-atlas.png",
    iconMapping,
    getIcon: (d: LayerCallbackObject) => colorMapper(d.type),
    sizeScale: 10,
    getPosition: (d: LayerCallbackObject) => d.coords,
    getSize: () => 1.5,
    autoHighlight: true,
    highlightColor: [0, 0, 0, 64],
    loadOptions: {
      imagebitmap: {
        premultiplyAlpha: "premultiply",
      },
    },
    LayerClass: IconLayer,
    hoverTypeName: "pin",
  };

  const material = {
    ambient: 0.8,
    diffuse: 0.3,
    shininess: 32,
    specularColor: [51, 51, 51],
  };

  const scaleByLog = (values) => {
    let totalVolumes = values.reduce((total, { count }) => total + count, 0);
    let scale = Math.floor(Math.log(Math.ceil(totalVolumes / 4)));
    if (isNaN(scale)) return 0;
    return scale;
  };

  const scalingHexLayer = {
    id: "hexagon-layer",
    data,
    pickable: true,
    extruded: mapView === "hex3d",
    radius: 150000 * getHexSizeScale(zoomLevel),
    coverage: 0.8,
    elevationScale: 1500 * getHexSizeScale(zoomLevel),
    colorRange: hexColors,
    material,
    getPosition: (d: LayerCallbackObject) => d.coords,
    getElevationValue: scaleByLog,
    getColorValue: scaleByLog,
    autoHighlight: true,
    highlightColor: [0, 0, 0, 64],
    LayerClass: HexagonLayer,
    hoverTypeName: "hex",
    updateTriggers: {
      getElevationValue: [zoomLevel],
      getColorValue: [zoomLevel],
    },
  };

  const hexLayer = {
    id: "hexagon-layer",
    data,
    pickable: true,
    extruded: mapView === "hex3d",
    radius: 75000,
    coverage: 0.8,
    elevationScale: 1500,
    colorRange: hexColors,
    material,
    getPosition: (d: LayerCallbackObject) => d.coords,
    getElevationValue: scaleByLog,
    getColorValue: scaleByLog,
    autoHighlight: true,
    highlightColor: [0, 0, 0, 64],
    LayerClass: HexagonLayer,
    hoverTypeName: "hex",
  };

  return mapView === "default"
    ? pinLayer
    : useHexScaling
    ? scalingHexLayer
    : hexLayer;
};

export default useReportingMapLayers;
