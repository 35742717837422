import React, { useEffect } from "react";
import NavBarItem from "./navBarItem";
import { LogOut, Nav, NavItems, NavWrapper } from "./styles";
import { ViewConfig } from "../../hooks/globalContext/types";
import { useLocation } from "react-router-dom";
import { useAuth } from "context/authContext";

type NavBarProps = {
  viewConfig: ViewConfig;
};

export const NavBar = ({ viewConfig }: NavBarProps) => {
  const { pathname } = useLocation();
  const { logout } = useAuth();

  useEffect(() => {
    //Scroll to top of page when navigating between pages, this is necessary since we have a singular page component
    document.body.scrollTop = document.documentElement.scrollTop = 0;
  }, [pathname]);

  const checkActiveRoute = (route: string) => pathname === route;
  const pages = viewConfig?.pages ? viewConfig.pages : [];
  return (
    <NavWrapper>
      <Nav>
        <NavItems>
          {viewConfig?.iconUrl && (
            <img src={viewConfig.iconUrl} alt="Logo" className="logo" />
          )}
          {pages.map((page, i) => {
            return (
              <NavBarItem
                {...page}
                active={checkActiveRoute(page.route)}
                key={page.route}
              />
            );
          })}
        </NavItems>
        <LogOut onClick={logout}>Log Out</LogOut>
      </Nav>
    </NavWrapper>
  );
};

export default NavBar;
