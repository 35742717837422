import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  DataCardData,
  DataCardIcon,
  DataCardPercentage,
  DataCardWrapper,
} from "./dataCard.styles";
import { useAnalyticsFormatting } from "hooks/useAnalyticsFormatting";
import getIcon from "utils/getIcon";

export type DataCardProps = {
  icon: string;
  color: string;
  value: string;
  title: string;
  percentChange: number;
};

const DataCard: React.FC<DataCardProps> = ({
  icon,
  color,
  value,
  title,
  percentChange,
}) => {
  const format = useAnalyticsFormatting();
  return (
    <DataCardWrapper>
      <DataCardIcon color={color}>
        <FontAwesomeIcon icon={getIcon(icon)} color={color} size="3x" />
      </DataCardIcon>
      <DataCardData>
        <h1>{value}</h1>
        <p>{title}</p>
      </DataCardData>
      {percentChange !== null && percentChange !== undefined && (
        <DataCardPercentage value={percentChange}>
          {format(percentChange * 100, null, {
            formatType: "addition",
            showEmptyData: true,
          })}
          %
        </DataCardPercentage>
      )}
    </DataCardWrapper>
  );
};

export default DataCard;
