import { useBusinessMappings } from "hooks/useBusinessMappings";
import getIcon from "utils/getIcon";
import { SeekerProfile } from "../types";
import {
  FaithJourneyWrapper,
  JourneyItemsWrapper,
  Logo,
} from "./faithJourney.styles";
import FaithJourneyItem from "./faithJourneyItem";

type JourneyConfig = string[];

type FaithJourneyProps = {
  profile: SeekerProfile;
  config: JourneyConfig;
};

const FaithJourney: React.FC<FaithJourneyProps> = ({ profile, config }) => {
  const { mapMetadata } = useBusinessMappings();

  let statusIndex = config.indexOf(profile.status);

  return (
    <FaithJourneyWrapper>
      <h2>Faith Journey</h2>
      <JourneyItemsWrapper>
        {config.map((type, i) => {
          let { userFriendlyLabel, relatedColor, relatedIcon } =
            mapMetadata(type);
          return (
            <FaithJourneyItem
              key={type}
              title={userFriendlyLabel}
              icon={getIcon(relatedIcon)}
              color={relatedColor}
              itemType={
                i === 0 ? "first" : i === config.length - 1 ? "last" : "middle"
              }
              active={i <= statusIndex}
              completedDate={profile.metrics[type]}
            />
          );
        })}
      </JourneyItemsWrapper>
      <Logo>
        <p>Powered By</p>
        <img
          src={`${process.env.REACT_APP_S3_URL}logo/logo.png`}
          alt="Unite Life"
        />
      </Logo>
    </FaithJourneyWrapper>
  );
};

export default FaithJourney;
