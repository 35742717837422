import { Filters, serializeDateRange } from "hooks/globalContext";
import HighLevelMetrics from "components/highLevelMetrics";
import {
  HighLevelMetricsHeader,
  HighLevelMetricsWrapper,
} from "./configDrivenHighLevelMetrics.styles";
import DateRangeDropDown from "components/date-range";
import { DateRangeDropDownProps } from "components/date-range/dateRange";
import { useCallback, useEffect, useState } from "react";
import {
  DateRange,
  DateRangeBinWidthKey,
  DateRangesAreEqual,
} from "utils/dateRange";
import { dateRangePresets } from "components/date-range/useDateRange";
import { useGlobalAnalytics } from "hooks/useGlobalAnalytics";
import { MetadataMapper, useBusinessMappings } from "hooks/useBusinessMappings";
import {
  AnalyticsFormatFunction,
  useAnalyticsFormatting,
} from "hooks/useAnalyticsFormatting";
import { HighLevelMetricConfig } from "components/highLevelMetrics/highLevelMetrics";
import generateHexColors from "utils/generateHexColors";

type ConfigDrivenHighLevelMetricConfig = {
  analyticType: string; // = sum of analyticSubTypes
  analyticSubTypes: string[];
};

type ConfigDrivenHighLevelMetricProps = {
  loading: boolean;
  config: {
    title: string;
    metrics: ConfigDrivenHighLevelMetricConfig[];
    initialRangeKey: DateRangeDropDownProps["initialRangeKey"];
    shouldInheritPageFilters: boolean;
    filters: Filters;
  };
  pageFilters: Filters;
  key?: string;
};

const ConfigDrivenHighLevelMetric: React.FC<
  ConfigDrivenHighLevelMetricProps
> = (props) => {
  const [dateRange, setDateRange] = useState<DateRange>({
    ...dateRangePresets[props.config.initialRangeKey].getDateRange(),
    binWidth: "total",
  });
  const [filters, setFilters] = useState(
    props.config.shouldInheritPageFilters
      ? props.pageFilters
      : props.config.filters
  );

  const allDependencies = getAllDependencies(props.config.metrics, dateRange);
  const {
    loading: loadingDependencies,
    dependencyData,
    dependencies,
    setDependencies,
    setCanFetch,
  } = useGlobalAnalytics({
    dependencies: allDependencies,
    dateRange,
    filters,
  });
  useEffect(() => {
    if (props.config.shouldInheritPageFilters) {
      setFilters(props.pageFilters);
    }
  }, [props.pageFilters, props.config.shouldInheritPageFilters]);

  const [metricData, setMetricData] = useState(
    dependencyData[serializeDateRange(dateRange)] || {}
  );

  useEffect(() => {
    const currentDateRangeKey = serializeDateRange(dateRange);
    if (!(props.loading || loadingDependencies)) {
      setMetricData(dependencyData[currentDateRangeKey] || {});
    }
  }, [dependencyData, props.loading, loadingDependencies, dateRange]);

  useEffect(() => {
    if (!props.loading) {
      setCanFetch(true);
    } else {
      setCanFetch(false);
    }
  }, [props.loading, setCanFetch]);

  useEffect(() => {
    setDependencies(
      dependencies.map((dependency) => {
        return { ...dependency, filters };
      })
    );
    //eslint-disable-next-line
  }, [filters]);

  /**
   * for this component, we'll want to update the date range for
   * all dependencies unilaterally when the date range changes
   */
  useEffect(() => {
    if (
      dateRange &&
      !dependencies.every((dependency) =>
        DateRangesAreEqual(dateRange, dependency.dateRange)
      )
    ) {
      setDependencies(
        dependencies.map((dependency) => ({
          ...dependency,
          dateRange: dateRange,
        }))
      );
    }
    //eslint-disable-next-line
  }, [dateRange, dependencies]);

  /** get headers -- headers are the bin names for this table */
  const { mapMetadata } = useBusinessMappings();
  const format = useAnalyticsFormatting();

  /**  */
  const metrics = formatMetrics(
    metricData,
    props.config.metrics,
    mapMetadata,
    format
  );

  const onDateRangeChange = (range: DateRange) => {
    if (range) {
      let newRange = {
        ...range,
        binWidth: "total" as DateRangeBinWidthKey,
      };
      if (!DateRangesAreEqual(newRange, dateRange)) {
        setDateRange(newRange);
      }
    }
  };

  const dateRangeChangeCallback = useCallback(onDateRangeChange, [dateRange]);

  return (
    <HighLevelMetricsWrapper key={props.key}>
      <HighLevelMetricsHeader>
        <h2>{props.config.title}</h2>
        <DateRangeDropDown
          handleDateRangeChange={dateRangeChangeCallback}
          initialRangeKey={props.config.initialRangeKey}
        />
      </HighLevelMetricsHeader>
      <HighLevelMetrics metrics={metrics} />
    </HighLevelMetricsWrapper>
  );
};

const formatMetrics = (
  data: any,
  metricConfigs: ConfigDrivenHighLevelMetricConfig[],
  mapper: MetadataMapper,
  format: AnalyticsFormatFunction
) => {
  const metricProps = metricConfigs.map((config) => {
    const metricTotal = Object.values(
      data[config.analyticType] || {}
    )?.[0] as unknown as number;

    const metricData = config.analyticSubTypes.map((type) => {
      const metadata = mapper(type);
      const subTotal = Object.values(
        data[type] || {}
      )?.[0] as unknown as number;
      return {
        title: metadata.userFriendlyLabel,
        value: subTotal,
        valueFormatted: format(subTotal, type, { showEmptyData: true }),
      };
    });

    const metadata = mapper(config.analyticType);
    const props: HighLevelMetricConfig = {
      title: metadata.userFriendlyLabel,
      total: format(metricTotal, config.analyticType, { showEmptyData: true }),
      data: metricData,
      colorScheme: generateHexColors(metadata.relatedColor, "hex").slice(
        0,
        metricData?.length || 4
      ) as string[],
      averageCost: 0,
    };
    return props;
  });
  return metricProps;
};

const getAllDependencies = (
  rows: ConfigDrivenHighLevelMetricConfig[],
  dateRange: DateRange
) => {
  return [...getAllDependenciesGenerator(rows, dateRange)];
};
/**using a generator to get a nice flat data structure */
function* getAllDependenciesGenerator(
  rows: ConfigDrivenHighLevelMetricConfig[],
  dateRange: DateRange
) {
  const dateRangeWithTotalBinWidth = {
    ...dateRange,
    binWidth: "total" as DateRangeBinWidthKey,
  };

  for (const dependency of rows) {
    yield {
      analyticType: dependency.analyticType,
      dateRange: dateRangeWithTotalBinWidth,
    };
    for (const subDependency of dependency.analyticSubTypes) {
      yield {
        analyticType: subDependency,
        dateRange: dateRangeWithTotalBinWidth,
      };
    }
  }
}
const Factory = (props: ConfigDrivenHighLevelMetricProps) => ({
  Component: ({ key, loading, pageFilters }) =>
    ConfigDrivenHighLevelMetric({ ...props, key, pageFilters, loading }),
  getInitialDependencies: () => {
    return getAllDependencies(
      props.config.metrics,
      dateRangePresets[props.config.initialRangeKey].getDateRange()
    );
  },
});
export default Factory;
