import Card from "components/card";
import {
  GraphKey,
  GraphKeyColor,
  GraphKeyItem,
  HighLevelMetricWrapper,
  MetricsRow,
} from "./highLevelMetrics.styles";
// import Metric from "components/metric";
import HighLevelMetricGraph from "./highLevelMetricGraph";
// import { faDollarSign } from "@fortawesome/free-solid-svg-icons";

// const defaultColorConfig = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042"];

export type HighLevelMetricConfig = {
  title: string;
  total: string;
  data: {
    title: string;
    value: number;
    valueFormatted: string;
  }[];
  averageCost: number;
  colorScheme: string[];
};

export type HighLevelMetricProps = {
  metrics: HighLevelMetricConfig[];
};

const fillMissingDataKeys = (data: HighLevelMetricConfig["data"]) => {
  let newData = [...data];
  if (data.length < 4) {
    for (let i = 0; i < 4 - data.length; i++) {
      newData.push({ title: "---", value: 0, valueFormatted: " " });
    }
  }
  return newData;
};

const HighLevelMetric: React.FC<HighLevelMetricProps> = ({ metrics }) => {
  return (
    <MetricsRow>
      {metrics.map(({ title, total, data, colorScheme }, i) => (
        <Card key={i}>
          <HighLevelMetricWrapper>
            <HighLevelMetricGraph
              title={title}
              total={total}
              types={data}
              colorConfig={colorScheme}
            />
            <GraphKey>
              {fillMissingDataKeys(data).map(({ title, valueFormatted }, i) => (
                <GraphKeyItem key={i}>
                  <GraphKeyColor color={colorScheme[i]} />
                  <h5>{title}</h5>
                  <h5>{valueFormatted}</h5>
                </GraphKeyItem>
              ))}
            </GraphKey>
            {/* <Metric
              icon={faDollarSign}
              title={`Cost per ${title}`}
              value={format(averageCost, null, { formatType: "smallCurrency" })}
            /> */}
          </HighLevelMetricWrapper>
        </Card>
      ))}
    </MetricsRow>
  );
};

export default HighLevelMetric;
