import React, { useEffect, useState } from "react";
import { BubbleRowWrapper } from "./styles";
import { DataBubbleRow } from "../../components/dataBubbleRow";
import { useBusinessMappings } from "hooks/useBusinessMappings";

import {
  Dependency,
  serializeDateRange,
} from "../../hooks/globalContext/globalContext";
import { useGlobalAnalytics } from "../../hooks/useGlobalAnalytics";
import { Filters } from "../../hooks/globalContext";
import {
  DateRange,
  DateRangeConfig,
  dateRangeFromConfig,
} from "../../utils/dateRange";
import { DataBubbleProps } from "../../components/dataBubble/dataBubble";
import { useAnalyticsFormatting } from "hooks/useAnalyticsFormatting";
import DataBubbleRowPlaceholder from "components/dataBubbleRow/dataBubbleRowPlaceholder";

export type BubbleRowProps = {
  config: {
    title: string;
    subtitle: string;
    bubbles: {
      analyticType: string;
      backgroundColor: string;
      fontColor: string;
    }[];
    dateRangeConfig: DateRangeConfig;
    filters: Filters;
  };
  loading: boolean;
  pageFilters: Filters;
  key: number;
};
export const BubbleRow: React.FC<BubbleRowProps> = (props) => {
  const filters = props.config.filters
    ? props.config.filters
    : props.pageFilters
    ? props.pageFilters
    : {};
  const dateRange = dateRangeFromConfig(props.config.dateRangeConfig);

  const allDependencies = getAllDependencies(
    props.config.bubbles,
    dateRange,
    filters
  );
  const { loading, dependencyData: nestedDependencies } = useGlobalAnalytics({
    dependencies: allDependencies,
    dateRange: dateRange,
    filters: props.config.filters,
  });
  const [dependencyData, setDependencyData] = useState(
    nestedDependencies[serializeDateRange(dateRange)] || {}
  );
  useEffect(() => {
    setDependencyData(nestedDependencies[serializeDateRange(dateRange)] || {});
  }, [nestedDependencies, dateRange]);
  const { mapLabel } = useBusinessMappings();
  const format = useAnalyticsFormatting();

  const bubbles: DataBubbleProps[] = props.config.bubbles.map((bubble) => {
    return {
      title: mapLabel(bubble.analyticType),
      value: format(
        Object.values(
          dependencyData[bubble.analyticType] || {}
        )?.[0] as unknown as string,
        bubble.analyticType,
        { showEmptyData: true }
      ),
      backgroundColor: bubble.backgroundColor,
      fontColor: bubble.fontColor,
      diameter: "240px",
    };
  });

  return (
    <BubbleRowWrapper>
      {!loading ? (
        <DataBubbleRow
          rowTitle={props.config.title}
          subTitle={props.config.subtitle}
          bubbles={bubbles}
        />
      ) : (
        <DataBubbleRowPlaceholder
          rowTitle={props.config.title}
          subTitle={props.config.subtitle}
          numRows={props.config.bubbles.length}
        />
      )}
    </BubbleRowWrapper>
  );
};
/**wrapper for the below generator to clean up the function signature */
const getAllDependencies: (
  bubbles: BubbleRowProps["config"]["bubbles"],
  dateRange: DateRange,
  filters: Filters
) => Dependency[] = (bubbles, dateRange, filters) => {
  return bubbles.map((bubble) => ({
    analyticType: bubble.analyticType,
    dateRange,
    filters,
  }));
};
export const Factory = (props: BubbleRowProps) => {
  return {
    Component: ({ key, pageFilters, loading }) =>
      BubbleRow({ ...props, key, pageFilters, loading }),
    getInitialDependencies: () => {
      return getAllDependencies(
        props.config.bubbles,
        dateRangeFromConfig(props.config.dateRangeConfig),
        props.config.filters
      );
    },
  };
};
export default Factory;
