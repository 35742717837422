import { useEffect, useRef, useState } from "react";
import ScrollContainer from "react-indiana-drag-scroll";
import { ReportTableRow } from "../reportTable";
import {
  ReportTableBodyCell,
  ReportTableBodyHeaderRow,
  ReportTableBodyRow,
  ReportTableBodyWrapper,
} from "./reportTableBody.styles";

type ReportTableBodyProps = {
  rows: ReportTableRow[];
  columnHeaders: string[];
};

const ReportTableBody: React.FC<ReportTableBodyProps> = ({
  rows,
  columnHeaders,
}) => {
  const scrollContainerRef = useRef<HTMLElement>(null);

  const [isGrabbing, setIsGrabbing] = useState(false);

  useEffect(() => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollLeft =
        scrollContainerRef.current.scrollWidth;
    }
    //eslint-disable-next-line
  }, [scrollContainerRef, rows]);

  return (
    <ReportTableBodyWrapper>
      <ScrollContainer
        className="scroll-container"
        vertical={false}
        hideScrollbars={false}
        onStartScroll={() => setIsGrabbing(true)}
        onEndScroll={() => setIsGrabbing(false)}
        style={{
          cursor: isGrabbing ? "grabbing" : "grab",
        }}
        innerRef={scrollContainerRef}
      >
        <ReportTableBodyHeaderRow>
          {columnHeaders.map((header) => (
            <ReportTableBodyCell key={header}>{header}</ReportTableBodyCell>
          ))}
        </ReportTableBodyHeaderRow>
        {rows.map(({ data }, i) => (
          <ReportTableBodyRow key={i}>
            {data.map((value, j) => (
              <ReportTableBodyCell key={j}>{value}</ReportTableBodyCell>
            ))}
          </ReportTableBodyRow>
        ))}
      </ScrollContainer>
    </ReportTableBodyWrapper>
  );
};

export default ReportTableBody;
