import React from "react";
import { FixedColumn, FixedColumnCell } from "./styles";
import { DataTableRow } from "../types/dataTableRow";
import { fixedColumnType } from "../types/fixedColumnType";
import DataTableFixedCell from "./dataTableFixedCell";
interface DataTableFixedColumnProps {
  title: string;
  rows: DataTableRow[];
  columnType: fixedColumnType;
}

const fixedColumnTypeProps: {
  [key: string]: {
    valueAccessor: string;
    showTooltip: boolean;
  };
} = {
  metric: {
    valueAccessor: "title",
    showTooltip: true,
  },
  total: {
    valueAccessor: "total",
    showTooltip: false,
  },
};

const parseValues = (
  row: DataTableRow,
  valueAccessor: string
): { value: string; secondaryValue?: string } => {
  let initialValue = row[valueAccessor];
  if (initialValue?.value) {
    return initialValue;
  } else
    return {
      value: initialValue,
    };
};

const DataTableFixedColumn = ({
  title,
  rows,
  columnType,
}: DataTableFixedColumnProps) => (
  <FixedColumn>
    <FixedColumnCell>{title}</FixedColumnCell>
    {rows?.map((row, i) => {
      let otherProps = fixedColumnTypeProps[columnType];

      return (
        <DataTableFixedCell
          key={i}
          {...parseValues(row, otherProps.valueAccessor)}
          tooltipText={row.tooltipText}
          showTooltip={otherProps.showTooltip}
          rowHasSubData={!!row?.total?.secondaryValue}
          rowType={row.rowType}
        />
      );
    })}
  </FixedColumn>
);

export default DataTableFixedColumn;
