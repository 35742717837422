import styled from "styled-components";
import { Theme } from "../../theme";

export const cellHeight = "50px";
export const cellHeightWithSubData = "75px";
export const scrollableCellWidth = "150px";

export const Table = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: auto 1fr auto;
  border-radius: ${({ theme }) => theme?.utilities.borders["border-radius-md"]};
  overflow: hidden;
  background-color: ${({ theme }) => theme?.colors["ui-gray"]};
  box-shadow: 0px 2px 4px rgba(22, 38, 77, 0.2);

  &.show-border {
    border: 1px solid ${({ theme }) => theme?.colors["primary-dark"]};
  }

  &.no-scroll {
    width: max-content;
  }
`;

const orangeTheme = {
  "text-dark": "#333333",
  "primary-dark": "#fda533",
  "primary-darker": "#E48C1A",
};

const purpleTheme = {
  "text-dark": "#333333",
  "primary-dark": "#8d52ff",
  "primary-darker": "#7439E6",
};

const lightBlueTheme = {
  "text-dark": "#333333",
  "primary-dark": "#2eb0fe",
  "primary-darker": "#1597E5",
};

const overrideThemes = {
  orange: orangeTheme,
  purple: purpleTheme,
  lightblue: lightBlueTheme,
};

export const overrideColorTheme = (
  theme: Theme,
  colorOverride?: string
): Theme => {
  if (!colorOverride || !overrideThemes[colorOverride]) return theme;

  return {
    ...theme,
    colors: { ...theme.colors, ...overrideThemes[colorOverride] },
  };
};
