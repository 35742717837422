import { Partner } from "hooks/globalContext";
import React from "react";
import { useHistory, useLocation } from "react-router";
import Footer from "../../components/footer";
import { NavBar } from "../navBar";
import { LargeViewSwitcher } from "../largeViewSwitcher";
import { ViewSwitcher } from "../viewSwitcher";
import { ToggleSwitcher } from "../viewToggleSwitcher";
import { LayoutGrid } from "./styles";
import { ViewConfig } from "../../hooks/globalContext/types";

type OnChangeFunction = (index: number) => void;
type LayoutProps = {
  viewConfig: ViewConfig;
  views: ViewConfig[];
  onViewChange: OnChangeFunction;
  partnerData: Partner;
};

const viewSwitcherBreakpoint = 10;

export const Layout: React.FC<LayoutProps> = ({
  viewConfig,
  views,
  onViewChange,
  children,
  partnerData,
}) => {
  const { pathname } = useLocation();
  const history = useHistory();
  const handleViewChange = (index: number) => {
    const newViewPages = views[index].pages;
    //If the new view doesn't contain the current page, redirect to the default view
    if (!newViewPages.find(({ route }) => route === pathname)) {
      history.replace("/");
    }
    onViewChange(index);
  };
  const toggleViewSwitcherPartners = ["trinity", "unitelife"];
  return (
    <LayoutGrid>
      <NavBar viewConfig={{ iconUrl: partnerData.iconUrl, ...viewConfig }} />
      {toggleViewSwitcherPartners.includes(partnerData.name) ? (
        <ToggleSwitcher
          viewConfig={viewConfig}
          views={views}
          onChange={handleViewChange}
        />
      ) : views.length > viewSwitcherBreakpoint ? (
        <LargeViewSwitcher
          viewConfig={viewConfig}
          views={views}
          onChange={handleViewChange}
        />
      ) : (
        <ViewSwitcher
          viewConfig={viewConfig}
          views={views}
          onChange={handleViewChange}
        />
      )}
      {children}
      <Footer />
    </LayoutGrid>
  );
};

export default Layout;
