import ReportingMap from "components/reportingMap";
import ProfileMap from "components/profileMap";
import { SidebarProps } from "components/reportingMap/hooks/useReportingMapFilters";
import { MapWrapper } from "./styles";
import { Filters } from "hooks/globalContext";

type MapType = "reporting" | "profile" | "ytd" | "arc" | "hostNetwork";

const MapFactory = (mapType: MapType, props: any) => {
  switch (mapType) {
    case "reporting":
      return <ReportingMap {...props} />;
    case "profile":
      return <ProfileMap {...props} />;
    default:
      return <div>ERR: Missing Map Data</div>;
  }
};

type ConfigDrivenMapProps = {
  config: {
    title: string;
    mapType: MapType;
    defaultDropdownType?: "string";
    sidebar: SidebarProps;
  };
  pageFilters: Filters;
  loading: boolean;
  key: string;
};

const ConfigDrivenMap: React.FC<ConfigDrivenMapProps> = (props) => {
  return (
    <MapWrapper key={props.key}>
      {MapFactory(props.config.mapType, props)}
    </MapWrapper>
  );
};

export default ConfigDrivenMap;
