import Form from "components/form";
import styled from "styled-components";
export type GraphWrapperProps = {
  width?: number;
  height?: number;
};
export const GraphWrapper = styled.div<GraphWrapperProps>`
  width: 100%;
  height: fit-content;
  display: flex;
  overflow-x: hidden;
  white-space: nowrap;

  padding: ${({ theme }) => theme.utilities.spacing["space-md"]} 30px;

  .scroll-container {
    height: fit-content;
    width: 100%;
    overflow-x: auto;
    padding-bottom: ${({ theme }) => theme.utilities.spacing["space-sm"]};
    &::-webkit-scrollbar {
      height: 8px;
    }
    cursor: grab;

    &::-webkit-scrollbar-track {
      border-radius: ${({ theme }) =>
        theme.utilities.borders["border-radius-md"]};
      background: ${({ theme }) => theme.colors["ui-gray-dark"]};
      opacity: 0.4;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: ${({ theme }) =>
        theme.utilities.borders["border-radius-md"]};
      background: ${({ theme }) => theme.colors["scrollbar"]};
      &:hover {
        background: ${({ theme }) => theme.colors["table-line"]};
      }
    }
  }
`;

export const GraphControlWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: ${({ theme }) => theme.utilities.spacing["space-sm"]};
  margin-bottom: ${({ theme }) => theme.utilities.spacing["space-md"]};
  border-radius: ${({ theme }) => theme.utilities.borders["border-radius-md"]};
  background-color: ${({ theme }) => theme.colors?.["ui-blue"]};
  box-shadow: 0px 2px 4px rgba(22, 38, 77, 0.2);
`;

export const GraphDropdown = styled(Form.Dropdown)`
  transition: 0.2s;
  cursor: pointer;

  select {
    border: none;
    border-radius: 0;
    background-color: transparent;
    border-bottom: 2px solid ${({ theme }) => theme.colors["primary-dark"]};
  }

  &:hover {
    opacity: 0.9;
  }
`;
