import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";
import { cellHeight, cellHeightWithSubData } from "../styles";

export const FixedColumn = styled.div`
  display: flex;
  flex-direction: column;

  .scrollbar-spacer {
    height: 8px;
  }
`;

export const FixedColumnCell = styled.div`
  width: 100%;
  height: ${cellHeight};
  padding: ${({ theme }) => `0 ${theme.utilities.spacing["space-md"]}`};
  background-color: ${({ theme }) => theme?.colors?.["primary-dark"]};
  color: white;
  white-space: nowrap;
  border-top: 1px solid ${({ theme }) => theme?.colors?.["table-line-light"]};
  font-weight: bold;

  display: flex;
  flex-direction: column;
  justify-content: center;

  .value-row {
    display: flex;
    align-items: center;
  }

  &:first-child {
    text-transform: uppercase;
    letter-spacing: 2px;
  }

  &.sub-data {
    height: ${cellHeightWithSubData};
  }

  &:nth-child(even) {
    background-color: ${({ theme }) => theme?.colors?.["primary-darker"]};
  }

  &.main {
    background-color: ${({ theme }) => theme?.colors?.["primary-dark"]};
  }

  &.sub {
    background-color: ${({ theme }) => theme?.colors?.["primary-darker"]};
  }

  &:last-child {
    height: calc(${cellHeight} + 8px);

    &.sub-data {
      height: calc(${cellHeightWithSubData} + 8px);
    }
    padding-bottom: 8px;
  }
`;

export const SecondaryValue = styled.span`
  font-size: 0.7rem;
`;

export const SubRowIcon = styled(FontAwesomeIcon)`
  transform: rotate(90deg);
  margin-right: 15px;
`;
