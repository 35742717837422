import styled from "styled-components";

export const DataCardRowWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${({ theme }) => theme?.utilities.spacing["space-md"]};
  color: black;
`;

export const DataCardRowCards = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: ${({ theme }) => theme?.utilities.spacing["space-lg"]};
`;
