import { useEffect, useState } from "react";
import {
  DateOption,
  DateRange,
  DateRangesAreEqual,
  createDateRange,
} from "../../utils/dateRange";

type RangePreset = {
  name: string;
  getDateRange: (options?: {
    startDateOption: DateOption;
    endDateOption: DateOption;
  }) => DateRange;
};

export const dateRangePresets = {
  "30d": {
    name: "Last 30 Days",
    getDateRange: () => createDateRange("30-days-ago", "today", "daily"),
  } as RangePreset,
  "12w": {
    name: "Last 12 Weeks",
    getDateRange: () => createDateRange("12-weeks-ago", "today", "weekly"),
  } as RangePreset,
  "12m": {
    name: "Last 12 Months",
    getDateRange: () => {
      return createDateRange("beginning-of-11-months-ago", "today", "monthly");
    },
  } as RangePreset,
  custom: {
    name: "Custom",
    getDateRange: (
      { startDateOption, endDateOption } = {
        startDateOption: "today",
        endDateOption: "30-days-ago",
      }
    ) => {
      return createDateRange(startDateOption, endDateOption, "daily");
    },
  } as RangePreset,
};

export type RangeKey = keyof typeof dateRangePresets;

const useDateRanges = (initialDateRangeKey: RangeKey) => {
  const [activeRangeKey, setActiveRangeKey] =
    useState<RangeKey>(initialDateRangeKey);

  const [customDateRangeValues, setCustomDateRangeValues] = useState(
    dateRangePresets[activeRangeKey].getDateRange
  );

  const [currentRange, setCurrentRange] = useState<DateRange>();
  const [isCustom, setIsCustom] = useState(false);
  const handleCustomDateRangeChange = (data) => {
    setCustomDateRangeValues(data);
    setCurrentRange(
      dateRangePresets[activeRangeKey].getDateRange({
        startDateOption: data.startDate.dateOption,
        endDateOption: data.endDate.dateOption,
      })
    );
  };
  // when the range key updates, update the current date range
  useEffect(() => {
    if (!(activeRangeKey === "custom")) {
      const newRange = dateRangePresets[activeRangeKey].getDateRange();
      const newRangeIsDifferent = !DateRangesAreEqual(newRange, currentRange);
      if (newRangeIsDifferent) {
        setCurrentRange(newRange);
      }
    }
    //eslint-disable-next-line
  }, [activeRangeKey]);
  /** keep isCustom in sync with the current range key */
  useEffect(() => {
    if (activeRangeKey === "custom") {
      if (isCustom !== true) setIsCustom(true);
    } else {
      if (isCustom !== false) setIsCustom(false);
    }
  }, [activeRangeKey, isCustom]);
  const rangeKeyUpdateHandler = (optionName) => {
    //eslint-disable-next-line
    const [newKey, value] = Object.entries(dateRangePresets).find(
      ([key, { name }]) => name === optionName
    ) as unknown as [RangeKey, any];
    setActiveRangeKey(newKey);
  };

  return {
    options: Object.fromEntries(
      Object.entries(dateRangePresets).map(([key, { name }]) => [key, name])
    ),
    activeRangeKey,
    rangeKeyUpdateHandler,
    handleCustomDateRangeChange,
    currentRange,
    isCustom,
    customDateRangeValues,
  };
};

export default useDateRanges;
