import styled from "styled-components";

export const ProfileMapWrapper = styled.div`
  width: 100%;
  height: 100vh;
  position: relative;

  .profile-tooltip {
    filter: drop-shadow(0 1px 2px rgba(0, 0, 0, 0.5));
    transform: translateY(-50%);
    background-color: ${({ theme }) => theme?.colors["ui-gray-lighter"]};

    border-radius: ${({ theme }) =>
      theme?.utilities.borders["border-radius-sm"]};
    padding: ${({ theme }) => theme?.utilities.spacing["space-xs"]}
      ${({ theme }) => theme?.utilities.spacing["space-sm"]};
    h5 {
      color: ${({ theme }) => theme?.colors["primary-dark"]};
      white-space: nowrap;
    }
    p {
      font-size: 0.65rem;
      opacity: 0.8;
      &.blue {
        color: ${({ theme }) => theme?.colors["primary-dark"]};
      }

      &.orange {
        color: ${({ theme }) => theme?.colors["secondary-default"]};
      }

      &.green {
        color: #22814f;
      }
    }
  }
`;

export const ProfileMapKey = styled.div`
  position: absolute;
  bottom: ${({ theme }) => theme?.utilities.spacing["space-lg"]};
  right: ${({ theme }) => theme?.utilities.spacing["space-lg"]};
  padding: ${({ theme }) => theme?.utilities.spacing["space-sm"]};
  display: grid;
  grid-template-columns: 1fr auto;
  column-gap: ${({ theme }) => theme?.utilities.spacing["space-md"]};
  row-gap: ${({ theme }) => theme?.utilities.spacing["space-xs"]};
  align-items: center;
  justify-items: center;
  background-color: #00000090;
  border-radius: ${({ theme }) => theme?.utilities.borders["border-radius-md"]};
  transition: 0.3s ease;

  &.sidebar-open {
    right: calc(${({ theme }) => theme?.utilities.spacing["space-lg"]} + 420px);
  }

  .map-key-item {
    color: white;
    font-weight: 700;
    font-size: 0.85rem;
    width: 100%;
    text-align: left;
  }
`;

export const MapKeyItemColor = styled.div<{ color: string }>`
  width: 15px;
  height: 15px;
  border-radius: 50%;
  border: 1px solid white;
  margin-left: 5px;
  background-color: ${({ color }) => color};
`;
