import { useEffect } from "react";
import styled from "styled-components";

const Indicator = styled.div`
  position: fixed;
  bottom: 0;
  right: 0;
  padding: ${({ theme }) => theme.utilities?.spacing?.["space-xs"]}
    ${({ theme }) => theme.utilities?.spacing?.["space-sm"]};
  color: white;
  background-color: #b52a2a;
  border-top-left-radius: ${({ theme }) =>
    theme.utilities?.borders?.["border-radius-sm"]};
  font-weight: bold;
`;

const StagingIndicator = () => {
  const isStaging =
    !!process.env.REACT_APP_STAGING &&
    window.location.hostname !== "blessed.reporting.pathwayinitiative.com";

  useEffect(() => {
    if (isStaging) {
      document.title = `Church Connect (Staging)`;
    }
    //eslint-disable-next-line
  }, []);

  if (!isStaging) return null;

  return <Indicator>Staging</Indicator>;
};

export default StagingIndicator;
