import { IconLayer } from "@deck.gl/layers";
import generateIconMapping from "utils/generateIconMapping";

type LayerCallbackObject = { status: string; coords: number[] };
const iconMapping = generateIconMapping(3, 3);

const useProfileMapLayers = (
  profiles: any[],
  onProfileClick: Function,
  colorMapper: (type: string) => string
) => {
  const profileLayer = {
    id: "profiles",
    data: profiles,
    pickable: true,
    billboard: false,
    iconAtlas: "icon-atlas.png",
    iconMapping,
    getIcon: (d: LayerCallbackObject) => colorMapper(d.status),
    autoHighlight: true,
    highlightColor: [0, 0, 0, 64],
    sizeScale: 10,
    getPosition: (d: LayerCallbackObject) => d.coords,
    getSize: 1.5,
    onClick: onProfileClick,
    loadOptions: {
      imagebitmap: {
        premultiplyAlpha: "premultiply",
      },
    },
    LayerClass: IconLayer,
    hoverTypeName: "profile",
  };
  return profileLayer;
};

export default useProfileMapLayers;
