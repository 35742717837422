import React, { useState } from "react";
import { Toggle, ToggleItems, ToggleSwitcherWrapper } from "./styles";
import { ViewConfig } from "../../hooks/globalContext";

// on change function params
// it will take one parameter, the index of the newly selected view
type OnChangeFunction = (index: number) => void;
type ViewComponentProps = {
  views: ViewConfig[];
  viewConfig: ViewConfig;
  onChange: OnChangeFunction;
};

export const ToggleSwitcher = ({
  views,
  viewConfig,
  onChange,
}: ViewComponentProps) => {
  const toggleActiveInitialState = views.findIndex(
    (view) => view.title === viewConfig.title
  ); 
  const [toggleActive, setToggleActive] = useState(
    !(toggleActiveInitialState === 0)
  );
  const toggleView = views[1];

  //If there's only one view, this component is not necessary
  if (views?.length === 1 || !views) {
    return null;
  }

  const handleChange = (value: boolean) => {
    setToggleActive(value);
    const index = !toggleActive ? 1 : 0;
    onChange(index);
  };
  
  return (
    <ToggleSwitcherWrapper>
      <ToggleItems>
        <Toggle
        >
          <p>{toggleView.title}</p>
          <div className="toggle-switch">
            <input
              type="checkbox"
              className="toggle-switch-checkbox"
              name="toggleSwitch"
              id="toggleSwitch"
              checked={toggleActive}
              onChange={(e) => handleChange(e.target.checked)}
            />
            <label className="toggle-switch-label" htmlFor="toggleSwitch">
              <span className="toggle-switch-inner" />
              <span className="toggle-switch-switch" />
            </label>
          </div>
        </Toggle>
      </ToggleItems>
    </ToggleSwitcherWrapper>
  );
};
export default ToggleSwitcher;
