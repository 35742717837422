import styled from "styled-components";
import Card from "../../card";

export const Menu = styled(Card)`
  z-index: 2;
  position: absolute;
  right: ${({ theme }) => theme.utilities?.spacing?.["space-xs"]};
  top: ${({ theme }) => theme.utilities?.spacing?.["space-xs"]};
  display: none;

  &.active {
    display: block;
  }
`;

export const MenuHeader = styled(Card.BodyHeader)`
  margin-bottom: ${({ theme }) => theme.utilities?.spacing?.["space-sm"]};
  h3 {
    font-size: 2rem;
  }
`;

export const MenuCollapseButton = styled.div`
  &:hover {
    cursor: pointer;
    .menu-icon__bar {
      background-color: ${({ theme }) => theme.colors?.["primary-dark"]};
    }
  }
  .menu-icon__bar {
    width: 24px;
    height: 3px;
    margin-bottom: 13px;
    border-radius: ${({ theme }) =>
      theme.utilities.borders?.["border-radius-md"]};
    background-color: ${({ theme }) => theme.colors?.["primary-light"]};
    transition: 0.2s ease;
    &:last-of-type {
      margin-bottom: 0;
    }
    &.menu-icon__bar--1 {
      transform-origin: top left;
      transform: rotate(45deg);
    }
    &.menu-icon__bar--3 {
      transform-origin: bottom left;
      transform: rotate(-45deg);
    }
  }
`;

export const MenuBody = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.utilities?.spacing?.["space-lg"]};

  > * {
    margin-top: 0;
  }
`;

export const MenuFooter = styled(Card.BodyFooter)`
  margin-top: ${({ theme }) => theme.utilities?.spacing?.["space-sm"]};

  button {
    width: 100%;
  }
`;
