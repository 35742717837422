import styled from "styled-components";

export const Wrapper = styled.div``;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: ${({ theme }) => theme.utilities.spacing["space-sm"]};
`;

export const Dropdowns = styled.div`
  display: flex;
  white-space: nowrap;
  gap: ${({ theme }) => theme.utilities.spacing["space-sm"]};
`;
