import tinycolor from "tinycolor2";

type OutputFormat = "hex" | "rgb";

const generateHexColors = (hex: string, outputFormat: OutputFormat = "rgb") => {
  const color = tinycolor(hex);

  return color
    .monochromatic()
    .sort((a, b) => b.getBrightness() - a.getBrightness())
    .map((color) => {
      if (outputFormat === "rgb") {
        let { r, g, b } = color.toRgb();
        return [r, g, b, 255];
      } else {
        return color.toHexString();
      }
    });
};

export default generateHexColors;
