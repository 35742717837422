import {
  ModalBackground,
  Profile,
  ProfileWrapper,
} from "./seekerProfile.styles";
import SeekerProfileHeader from "./seekerProfileHeader";
import FaithJourney from "./faithJourney";
import { SeekerProfile as SeekerProfileType } from "./types";

type SeekerProfileProps = {
  dashboardMode: boolean;
  setDashboardMode: (val: boolean) => void;
  leaving: boolean;
  setLeaving: (val: boolean) => void;
  selectedProfile: SeekerProfileType;
  setSelectedProfile: (profile: any) => void;
  journeyConfig: string[];
};

const SeekerProfile: React.FC<SeekerProfileProps> = ({
  dashboardMode,
  setDashboardMode,
  leaving,
  setLeaving,
  selectedProfile,
  setSelectedProfile,
  journeyConfig,
}) => {
  const onClose = () => {
    setLeaving(true);
    dashboardMode && setDashboardMode(false);
    setTimeout(() => {
      setSelectedProfile(null);
      setLeaving(false);
    }, 350);
  };

  return (
    <>
      <ModalBackground onClick={onClose} />
      <ProfileWrapper className={leaving && "leaving"}>
        <Profile>
          <SeekerProfileHeader profile={selectedProfile} onClose={onClose} />
          <FaithJourney profile={selectedProfile} config={journeyConfig} />
        </Profile>
      </ProfileWrapper>
    </>
  );
};

export default SeekerProfile;
