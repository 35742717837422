import styled from "styled-components";

export const ReportHeaderWrapper = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 150px auto 1fr;
  align-items: center;
  gap: ${({ theme }) => theme?.utilities.spacing["space-md"]};
`;

export const ReportHeaderDivider = styled.div`
  height: 2px;
  width: 100%;
  background-color: #00000025;
`;

export const ReportHeaderTitle = styled.div`
  h2 {
    font-size: 2rem;
    line-height: 2.5rem;
  }
  p {
    line-height: 1rem;
    font-size: 1rem;
    opacity: 0.5;
    font-weight: bold;
  }
`;
