import { useEffect, useState } from "react";
import {
  DisplayComponentDependencyData,
  Filters,
  Analytics,
  CheckDependenciesRequestedResults,
  useAnalyticsData,
  serializeDateRange,
} from "./globalContext";
import {
  CheckDependenciesFulfullmentResults,
  Dependency,
  serializeFilters,
  useGlobal,
  GlobalState,
} from "./globalContext";
import { DateRange, parseDateOption } from "../utils/dateRange";

export type UseGlobalAnalyticsProps = {
  dependencies: DisplayComponentDependencyData[];
  dateRange?: DateRange;
  filters?: Filters;
  isPage?: boolean;
};
/**
 *
 * @param props the data used to initialize the component
 * @returns
 */
export const useGlobalAnalytics = (props: UseGlobalAnalyticsProps) => {
  /** the analytics data  */
  const { checkDependenciesFulfillment, checkDependenciesRequested } =
    useGlobal();
  const { analyticsData, requestDependencies, analyticsLoadingStates } =
    useAnalyticsData();
  /** here we will keep track of whether or not all the dependencies for the child component is available */
  const getInitialDependencies: () => Dependency[] = () =>
    props.dependencies.map(({ analyticType, dateRange }) => ({
      analyticType,
      dateRange,
      filters: props.filters,
    }));
  const [canFetch, setCanFetch] = useState(!!props?.isPage);
  const [loading, setLoading] = useState(() => true);

  const [dependencies, setDependencies] = useState(getInitialDependencies);
  /** the data corresponding to the dependency objects. a subset of analyticsData from useGlobal */
  const [dependencyData, setDependencyData] = useState<{
    [key: string]: Analytics;
  }>({});
  /**
   * whether or not the central data store fulfills the dependencies.
   * essentially the loading state of this hook
   */
  const [dependenciesFulfilled, setDependenciesFulfilled] = useState(
    checkDependenciesFulfillment(dependencies, analyticsData)
  );
  const [dependenciesRequested, setDependenciesRequested] = useState(
    checkDependenciesRequested(dependencies, analyticsLoadingStates)
  );
  useEffect(() => {
    /** if not all deps are fulfilled, we should be in loading state */
    const shouldBeLoading = !allDependenciesFulfilled(dependenciesFulfilled);

    if (loading !== shouldBeLoading) {
      setLoading(shouldBeLoading);
    }
    //eslint-disable-next-line
  }, [dependenciesFulfilled]);

  /**
   * after updates to the dependencies array,
   * we will check to make sure there is still
   * enough data available to satisfy the new dependencies
   */
  useEffect(() => {
    const newDependenciesFulfilled = checkDependenciesFulfillment(
      dependencies,
      analyticsData
    );
    const newFulfillmentResults = allDependenciesFulfilled(
      newDependenciesFulfilled
    );
    const oldFulfillmentResults = allDependenciesFulfilled(
      dependenciesFulfilled
    );
    /** if the new computed value is different from the current value, update the current value */
    if (newFulfillmentResults !== oldFulfillmentResults) {
      setDependenciesFulfilled(newDependenciesFulfilled);
    }
    //eslint-disable-next-line
  }, [dependencies, analyticsData]);
  /**
   * after updates to the dependencies array,
   * we will check to make we have requested all dependencies
   * and update the state
   */
  useEffect(() => {
    const newDependenciesRequested = checkDependenciesRequested(
      dependencies,
      analyticsLoadingStates
    );
    const newFulfillmentResults = allDependenciesRequested(
      newDependenciesRequested
    );
    const oldFulfillmentResults = allDependenciesRequested(
      dependenciesRequested
    );

    /** if the new computed value is different from the current value, update the current value */
    if (newFulfillmentResults !== oldFulfillmentResults) {
      setDependenciesRequested(newDependenciesRequested);
    }
  }, [
    dependencies,
    analyticsLoadingStates,
    checkDependenciesRequested,
    dependenciesRequested,
  ]);

  useEffect(() => {
    if (
      !allDependenciesFulfilled(dependenciesFulfilled) &&
      !allDependenciesRequested(dependenciesRequested) &&
      canFetch
    ) {
      requestDependencies(dependencies);
    } else if (!loading) {
      setDependencyData(extractDependencies(dependencies, analyticsData));
    }
  }, [
    analyticsData,
    canFetch,
    dependencies,
    loading,
    dependenciesFulfilled,
    dependenciesRequested,
    requestDependencies,
  ]);

  return {
    dependenciesFulfilled,
    setDependencies,
    dependencyData,
    loading,
    dependencies,
    setCanFetch,
  };
};
const allDependenciesRequested = (
  dependenciesRequestedResults: CheckDependenciesRequestedResults
) => {
  const result = dependenciesRequestedResults.reduce(
    (acc, current) => acc && current.requested,
    true
  );

  return result;
};
const allDependenciesFulfilled = (
  dependenciesFulfilledResults: CheckDependenciesFulfullmentResults
) => {
  const result = dependenciesFulfilledResults.reduce(
    (acc, current) => acc && current.exists,
    true
  );
  return result;
};
const extractDependencies: (
  dependencies: Dependency[],
  analyticsData: GlobalState["analyticsData"]
) => { [key: string]: Analytics } = (dependencies, analyticsData) => {
  const analytics = dependencies.reduce((acc, current) => {
    const value =
      analyticsData[serializeFilters(current.filters)]?.[
        parseDateOption(current.dateRange.startDate.dateOption).getTime()
      ]?.[parseDateOption(current.dateRange.endDate.dateOption).getTime()]?.[
        current.dateRange.binWidth
      ]?.[current.analyticType];
    if (value) {
      const dateRangeKey = serializeDateRange(current.dateRange);
      return {
        ...acc,
        [dateRangeKey]: {
          ...acc[dateRangeKey],
          [current.analyticType]: value,
        },
      };
    } else {
      return acc;
    }
  }, {});
  return analytics;
};
