import {
  findIconDefinition,
  IconName,
  IconPrefix,
} from "@fortawesome/fontawesome-svg-core";

const getIcon = (icon: string) => {
  const hasIconPrefix = icon.split(" ").length > 1;
  let iconPrefix = "fas";
  let iconName = icon;
  if (hasIconPrefix) {
    let split = icon.split(" ");
    iconPrefix = split[0];
    iconName = split[1];
  }

  return findIconDefinition({
    prefix: iconPrefix as IconPrefix,
    iconName: iconName as IconName,
  });
};

export default getIcon;
