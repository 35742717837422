import styled from "styled-components";
import {
  cellHeight,
  cellHeightWithSubData,
  scrollableCellWidth,
} from "../styles";

export const TableData = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;

  &.no-scroll {
    width: max-content;
  }

  .scroll-container {
    &::-webkit-scrollbar {
      height: 8px;
    }

    &::-webkit-scrollbar-track {
      border-radius: ${({ theme }) =>
        theme?.utilities.borders["border-radius-md"]};
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      background: ${({ theme }) => theme?.colors?.["ui-gray-dark"]};
      opacity: 0.4;
      box-shadow: 0 0 2px 4px rgba(0, 0, 0, 0) inset;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: ${({ theme }) =>
        theme?.utilities.borders["border-radius-md"]};
      background: ${({ theme }) => theme?.colors?.["scrollbar"]};

      &:hover {
        background: ${({ theme }) => theme?.colors?.["table-line"]};
      }
    }
  }
`;

export const TableRow = styled.div`
  height: ${cellHeight};
  display: grid;
  grid-auto-columns: ${scrollableCellWidth};
  grid-auto-flow: column;
  align-items: center;
  width: fit-content;

  &.column-headers {
    font-weight: bold;
  }

  &.sub-data {
    height: ${cellHeightWithSubData};
  }

  &:nth-child(even) {
    background-color: ${({ theme }) => theme?.colors?.["ui-gray-dark"]};
  }

  &.main {
    background-color: ${({ theme }) => theme?.colors?.["ui-gray"]};
  }

  &.sub {
    background-color: ${({ theme }) => theme?.colors?.["ui-gray-dark"]};
  }

  &:first-child {
    border-bottom: 1px solid ${({ theme }) => theme?.colors?.["table-line"]};
  }
`;

export const TableCell = styled.div`
  width: ${scrollableCellWidth};
  height: ${cellHeight};
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: ${({ theme }) => theme?.colors?.["text-dark"]};

  padding: ${({ theme }) =>
    `${theme.utilities.spacing["space-sm"]} ${theme.utilities.spacing["space-md"]}`};
`;

export const SecondaryValue = styled.span`
  font-size: 0.7rem;
`;
